import { Card, styled, Typography } from '@mui/material';

export const LightCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export function HeaderTypography(props) {
  return <Typography color='text.subtitle' variant='small2' {...props} />;
}

export function ValueTypography(props) {
  return <Typography variant='body3' {...props} />;
}
