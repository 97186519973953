import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import OrderConfirmation from './OrderConfirmation';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 'min-content',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 3,
};

export function OrderConfirmationModal({
  limitPrice,
  FormAtoms,
  superStrategies,
  strategies,
  open,
  setOpen,
  data,
  handleConfirm,
  isBuy,
  convertedQty,
  modalText,
  optionSubmit,
  trajectories,
  isChainedOrder = false,
}) {
  return (
    <div>
      <Modal
        closeAfterTransition
        aria-describedby='transition-modal-description'
        aria-labelledby='transition-modal-title'
        open={open}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        slots={{ backdrop: Backdrop }}
        onClose={() => setOpen(false)}
      >
        <Fade in={open}>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            sx={modalStyle}
          >
            <OrderConfirmation
              order={data}
              onClose={() => setOpen(false)}
              onConfirm={handleConfirm}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
