import { Stack, Typography } from '@mui/material';
import React from 'react';

function OrderInfo({ label, children }) {
  return (
    <Stack direction='column' spacing={1}>
      <Typography color='secondary' variant='small1'>
        {label}
      </Typography>
      {children}
    </Stack>
  );
}

export default OrderInfo;
