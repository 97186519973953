import { NumericFormatCustom } from '@/shared/fields/NumberFieldFormat';
import { StrategyParamSelect } from '@/shared/fields/StrategyParamSelect';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import { ignoreScrollEvent } from '../../../util';

const expiries = [
  { seconds: 60, display: '1 minute' },
  { seconds: 120, display: '2 minutes' },
  { seconds: 300, display: '5 minutes' },
  { seconds: 600, display: '10 minutes' },
  { seconds: 900, display: '15 minutes' },
  { seconds: 1800, display: '30 minutes' },
  { seconds: 3600, display: '1 hour' },
  { seconds: 10800, display: '3 hours' },
  { seconds: 21600, display: '6 hours' },
  { seconds: 43200, display: '12 hours' },
  { seconds: 86400, display: '1 day' },
  { seconds: 432000, display: '5 days' },
];

const slices = [1, 2, 5, 10, 20, 50, 100];

function SimpleOrderFields({
  orderSlices,
  selectedAccountExchangeNames,
  selectedDuration,
  selectedStrategyParams,
  setOrderSlices,
  setSelectedDuration,
  setSelectedStrategyParams,
  setStopPrice,
  setUpdatePairLeverage,
  showSlices,
  stopPrice,
  strategyParams,
  updatePairLeverage,
}) {
  const handleStrategyParamChange = (event) => {
    setSelectedStrategyParams({
      ...selectedStrategyParams,
      [event.target.name]: event.target.checked,
    });
  };

  const noArrowStyle = {
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    'input[type=number]': {
      MozAppearance: 'textfield',
    },
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={showSlices ? 6 : 12}>
        <FormControl fullWidth>
          <InputLabel id='select-expiry-dropdown-label'>Expiry</InputLabel>
          <Select
            id='select-expiry-dropdown'
            label='Expiry'
            labelId='select-expiry-dropdown-label'
            value={selectedDuration}
            onChange={(e) => setSelectedDuration(e.target.value)}
          >
            {expiries.map((expiry) => (
              <MenuItem key={expiry.seconds} value={expiry.seconds}>
                {expiry.display}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {showSlices && (
        <Grid xs={6}>
          <FormControl fullWidth>
            <InputLabel id='select-slices-dropdown-label'>Slices</InputLabel>
            <Select
              id='select-slices-dropdown'
              label='Slices'
              labelId='select-slices-dropdown-label'
              value={orderSlices}
              onChange={(e) => setOrderSlices(e.target.value)}
            >
              {slices.map((slice) => (
                <MenuItem key={slice} value={slice}>
                  {slice}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      <Grid xs={12}>
        <StrategyParamSelect
          isSimple
          showHeading
          handleStrategyParamChange={handleStrategyParamChange}
          selectedAccountExchangeNames={selectedAccountExchangeNames}
          selectedStrategyParams={selectedStrategyParams}
          strategyParams={strategyParams}
        />
      </Grid>

      <Grid xs={6}>
        <TextField
          fullWidth
          InputProps={{ step: 'any', inputComponent: NumericFormatCustom }}
          placeholder='Stop Price'
          sx={noArrowStyle}
          value={stopPrice}
          onChange={(e) => setStopPrice(e.target.value)}
          onWheel={ignoreScrollEvent}
        />
      </Grid>

      <Grid xs={6}>
        <TextField
          fullWidth
          InputProps={{
            inputComponent: NumericFormatCustom,
          }}
          placeholder='Update Pair Leverage'
          sx={noArrowStyle}
          value={updatePairLeverage}
          onChange={(e) => setUpdatePairLeverage(e.target.value)}
          onWheel={ignoreScrollEvent}
        />
      </Grid>
    </Grid>
  );
}

export default SimpleOrderFields;
