import { useTheme } from '@emotion/react';
import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2'; // Import Bar instead of Doughnut
import { Legend } from 'chart.js';
import { Typography } from '@mui/material';
import { ThinLoader } from '../../Loader';

function FillExchangeChart({ data, height }) {
  const theme = useTheme();

  const placeholderData = {
    labels: ['Loading'],
    datasets: [
      {
        label: 'Placeholder',
        data: [1],
        backgroundColor: theme.palette.divider,
        borderRadius: 5,
        borderSkipped: false,
      },
    ],
  };

  const exchangeColors = {
    OKX: theme.palette.exchangeColors.OKXTransparent,
    Binance: theme.palette.exchangeColors.BinanceTransparent,
    Bybit: theme.palette.exchangeColors.BybitTransparent,
    Deribit: theme.palette.exchangeColors.DeribitTransparent,
    Coinbase: theme.palette.exchangeColors.CoinbaseTransparent,
    MockExchange: theme.palette.exchangeColors.MockExchangeTransparent,
    OKXOTC: theme.palette.exchangeColors.OKXTransparent,
  };
  const { breakdown_fill_exchange_data, breakdown_fill_exchange_labels } =
    data || {};

  const datasets = useMemo(() => {
    if (breakdown_fill_exchange_data !== undefined) {
      return Object.keys(breakdown_fill_exchange_data).map((key, index) => ({
        label: key,
        data: [breakdown_fill_exchange_data[key]],
        backgroundColor: exchangeColors[key] || theme.palette.scheme1[index],
        borderColor: exchangeColors[key] || theme.palette.scheme1[index],
      }));
    }
    return [];
  }, [breakdown_fill_exchange_data]);

  const noData =
    breakdown_fill_exchange_data === undefined ||
    Object.keys(breakdown_fill_exchange_data).length === 0;

  const fill_exchange_data = {
    labels: breakdown_fill_exchange_labels,
    datasets: datasets.map((dataset, index) => ({
      ...dataset,
      borderRadius: {
        // Adds the rounded border for the graph
        topLeft: index === 0 ? 5 : 0,
        topRight: index === datasets.length - 1 ? 5 : 0,
        bottomRight: index === datasets.length - 1 ? 5 : 0,
        bottomLeft: index === 0 ? 5 : 0,
      },
      borderSkipped: false, // Ensures the border radius applies to all corners
    })),
  };

  const options = {
    indexAxis: 'y',
    scales: {
      x: {
        stacked: true,
        ticks: {
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            color: theme.palette.text.secondary,
            fontSize: '10px',
          }}
        >
          Exchanges(s)
        </Typography>
        <div style={{ display: 'flex', gap: '20px' }}>
          {/* Custom Legend */}
          {fill_exchange_data.datasets.map((dataset, index) => (
            <div
              key={dataset.label}
              style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
            >
              <div
                style={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: dataset.backgroundColor,
                  borderRadius: '50%',
                }}
              />
              <Typography
                sx={{
                  fontSize: '10px',
                }}
              >
                {`${Number(dataset.data[0]).toFixed(0)}%`}
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.text.secondary,
                  fontSize: '10px',
                }}
              >
                {dataset.label}
              </Typography>
            </div>
          ))}
        </div>
      </div>
      <div style={{ height, width: '100%' }}>
        <Bar
          data={noData ? placeholderData : fill_exchange_data}
          height='48px'
          options={options}
        />
      </div>
    </>
  );
}

export { FillExchangeChart };
