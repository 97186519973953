import React, { useState } from 'react';
import { Box, Stack } from '@mui/material';
import ScaleLoader from 'react-spinners/ScaleLoader';

import {
  reSubmitAction,
  reSubmitRemainingAction,
} from '@/shared/orderDetail/OrderActionUtils';

import { Loader } from '@/shared/Loader';
import { BasicModal } from '@/shared/Modal';
import {
  ApiError,
  cancelMultiOrder,
  pauseOrder,
  resumeOrder,
  submitCancel,
} from '@/apiServices';
import { AmendOrderDialog } from '@/shared/AmendOrderDialog';
import {
  AmendButton,
  PauseResumeButton,
  CancelButton,
  ResubmitButton,
} from './OrderButtons';

function OrderActions({
  OrderSummaryData,
  showAlert,
  loadOrderData,
  algoDetailView = false,
}) {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [handleConfirm, setHandleConfirm] = useState(() => {});
  const [confirmModalText, setConfirmModalText] = useState('');
  const [amendDialogOpen, setAmendDialogOpen] = useState(false);

  if (!OrderSummaryData || Object.keys(OrderSummaryData).length === 0) {
    return <Loader />;
  }

  const OrderData = OrderSummaryData;

  const {
    id,
    account_names,
    pair,
    side,
    parent_order = undefined,
    pov_target,
    status,
    super_strategy_name,
    unique_venues,
  } = OrderSummaryData;

  const handleCancel = async () => {
    setConfirmModalOpen(false);
    try {
      if (parent_order) {
        await cancelMultiOrder(id);
      } else {
        await submitCancel(id);
      }
      showAlert({
        severity: 'success',
        message: 'Successfully canceled the specified order.',
      });
    } catch (e) {
      if (e instanceof ApiError) {
        showAlert({ severity: 'error', message: e.message });
      } else {
        throw e;
      }
    }

    loadOrderData(id);
  };

  const handlePause = async () => {
    setConfirmModalOpen(false);
    try {
      await pauseOrder(id);
      showAlert({
        severity: 'success',
        message: 'Successfully paused the specified order.',
      });
    } catch (e) {
      if (e instanceof ApiError) {
        showAlert({ severity: 'error', message: e.message });
      } else {
        throw e;
      }
    }

    loadOrderData(id);
  };

  const handleResume = async () => {
    setConfirmModalOpen(false);
    try {
      await resumeOrder(id);
      showAlert({
        severity: 'success',
        message: 'Successfully resumed the specified order.',
      });
    } catch (e) {
      if (e instanceof ApiError) {
        showAlert({ severity: 'error', message: e.message });
      } else {
        throw e;
      }
    }

    loadOrderData(id);
  };

  const handleResubmit = async () => {
    reSubmitAction({
      row: OrderData,
      openNewTabOnSubmit: false,
      showAlert,
    });
  };
  const handleResubmitRemaining = async () => {
    reSubmitRemainingAction({
      row: OrderData,
      openNewTabOnSubmit: false,
      showAlert,
    });
  };

  return (
    <Box
      alignItems='center'
      justifyContent='center'
      sx={{ width: '100%', height: '50px' }}
    >
      <Stack
        alignItems='center'
        backgroundColor='background.paper'
        direction='row'
        justifyContent='center'
        spacing={1}
        sx={{
          width: algoDetailView ? '500px' : '100%',
          height: '50px',
          marginLeft: algoDetailView ? '-16px' : 'auto',
        }}
      >
        <ResubmitButton
          handleResubmit={handleResubmit}
          handleResubmitRemaining={handleResubmitRemaining}
          setConfirmModalOpen={setConfirmModalOpen}
          setConfirmModalText={setConfirmModalText}
          status={status}
        />
        <AmendButton
          parentOrder={parent_order}
          setAmendDialogOpen={setAmendDialogOpen}
          status={status}
        />
        <PauseResumeButton
          handlePause={handlePause}
          handleResume={handleResume}
          isPaused={status === 'PAUSED'}
          parent_order={parent_order}
          setConfirmModalOpen={setConfirmModalOpen}
          setConfirmModalText={setConfirmModalText}
          setHandleConfirm={setHandleConfirm}
          status={status}
          super_strategy_name={super_strategy_name}
        />
        <CancelButton
          handleCancel={handleCancel}
          setConfirmModalOpen={setConfirmModalOpen}
          setConfirmModalText={setConfirmModalText}
          setHandleConfirm={setHandleConfirm}
          status={status}
        />
      </Stack>
      <BasicModal
        confirmButtonText='Yes'
        handleConfirm={handleConfirm}
        message={confirmModalText}
        open={confirmModalOpen}
        setOpen={setConfirmModalOpen}
      />
      <AmendOrderDialog
        amendDialogOpen={amendDialogOpen}
        exchangeNames={unique_venues}
        orderAccounts={account_names}
        orderId={id}
        OrderSummaryData={OrderSummaryData}
        pair={pair}
        pov_target={pov_target}
        setAmendDialogOpen={setAmendDialogOpen}
        showAlert={showAlert}
        side={side}
      />
    </Box>
  );
}

export { OrderActions };
