import { theme } from '@/theme/theme';
import { Box, Stack, Paper, Tooltip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { TreadTooltip } from '@/shared/components/LabelTooltip';
import { StyledIBMTypography } from './orderTable/util';

function PreTradeAnalyticsCard({ children }) {
  return (
    <Paper
      elevation={1}
      sx={{
        height: '100%',
        boxSizing: 'border-box',
      }}
    >
      {children}
    </Paper>
  );
}

function MarketVolumeNA() {
  return (
    <Stack
      flexDirection="column"
      justifyContent="space-between"
      minHeight="40px"
      spacing={0.5}
    >
      <Box sx={{ padding: '8px' }}>
        <Box>
          <TreadTooltip
            labelTextVariant="h6"
            placement="top"
            title={
              <div>
                <Typography sx={{ marginBottom: 1.5 }}>
                Shows the aggregated market activity across this leg over the past 24 hours relative to expected seasonal volume.
                </Typography>
                <Typography>Market volume data not available.</Typography>
              </div>
            }
            variant="market_volume"
          />
          </Box>
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <Typography
              sx={{
                fontWeight: 600,
                textDecorationColor: 'rgba(255,255,255,0.5)',
              }}
              variant="h6"
            >
              N/A
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
}

function MarketVolumeData({ color, tag, message, marketVolume }) {
  return (
    <Stack
      flexDirection="column"
      justifyContent="space-between"
      minHeight="40px"
      spacing={0.5}
    >
      <Box sx={{ padding: '8px' }}>
        <Box>
          <TreadTooltip
            labelTextVariant="h6"
            placement="top"
            title={
              <div>
                <Typography sx={{ marginBottom: 1.5 }}>
                  Shows the aggregated market activity across this leg over the past 24 hours relative to expected seasonal volume.
                </Typography>
                <Typography>{message}</Typography>
              </div>
            }
            variant="market_volume"
          />
          </Box>
          <Box alignItems="center" display="flex" justifyContent="space-between">
            {marketVolume !== null ? (
              <StyledIBMTypography
                color={color}
                style={{
                  fontWeight: 600,
                }}
              >
                {tag} {Number(marketVolume).toFixed(2)}x
              </StyledIBMTypography>
            ) : (
              <Typography
                sx={{
                  fontWeight: 600,
                  textDecorationColor: 'rgba(255,255,255,0.5)',
                }}
                variant="h6"
              >
                N/A
              </Typography>
          )}
        </Box>
      </Box>
    </Stack>
  );
}

function ParticipationRate({ buyAnalytics, sellAnalytics }) {
  const generateGuideline = () => {
    const buyPov = buyAnalytics?.pov || null;
    const sellPov = sellAnalytics?.pov || null;

    if (buyPov === null && sellPov === null) {
      return 'Fill in valid order parameters to see analytics.';
    }

    const displayPov = buyPov !== null ? buyPov : sellPov;

    if (displayPov < 0.5) {
      return 'Minimum impact expected.';
    }
    if (displayPov < 1) {
      return 'Moderate impact expected, consider increasing duration or adding more venues.';
    }

    return 'High impact expected, increasing duration and adding more venues is recommended.';
  };

  return (
    <Stack
      flexDirection="column"
      justifyContent="space-between"
      minHeight="40px"
      spacing={0.5}
    >
      <Box sx={{ padding: '8px' }}>
        <Box>
          <TreadTooltip
            labelTextVariant="h6"
            placement="top"
            title={
              <div>
                <Typography sx={{ marginBottom: 1.5 }}>
                  Shows the proportion of the predicted market volume your order is expected to take up during its duration across your pairs aggregated for this leg.
                </Typography>
                <Typography>
                  {generateGuideline()}
                </Typography>
              </div>
            }
            variant="participation_rate"
          />
          </Box>
          <Box alignItems="center" display="flex" justifyContent="space-between">
             <StyledIBMTypography
              sx={{
                fontWeight: 600,
                textDecorationColor: 'rgba(255,255,255,0.5)',
                color: (() => {
                  const participationRate = buyAnalytics.pov || sellAnalytics.pov;
                  if (!participationRate) {
                    return theme.palette.text.primary;
                  }
                  if (participationRate < 0.5) {
                    return theme.palette.success.main;
                  }
                  if (participationRate < 1) {
                    return theme.palette.warning.main;
                  }
                  return theme.palette.error.main;
                })(),
              }}
            >
              {buyAnalytics.pov || sellAnalytics.pov
                ? `${(buyAnalytics.pov || sellAnalytics.pov).toFixed(4)}%`
                : 'N/A'}
          </StyledIBMTypography>
        </Box>
      </Box>
    </Stack>
  );
}

function MarketVolatility({ buyAnalytics, sellAnalytics }) {
  return (
    <Stack
      flexDirection="column"
      justifyContent="space-between"
      minHeight="40px"
      spacing={0.5}
    >
      <Box sx={{ padding: '8px' }}>
        <Box>
          <TreadTooltip
            labelTextVariant="h6"
            placement="top"
            title={
              <div>
                <Typography>Shows the expected price movement during the order, calculated as a notional-weighted average of realized volatility across all orders in this leg.</Typography>
              </div>
            }
            variant="market_volatility"
          />
          </Box>
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <StyledIBMTypography
              sx={{
                fontWeight: 600,
                textDecorationColor: 'rgba(255,255,255,0.5)',
              }}
            >
              &plusmn;
              {buyAnalytics.volatility || sellAnalytics.volatility
                ? `${((buyAnalytics.volatility || sellAnalytics.volatility)).toFixed(4)}%`
                : 'N/A%'}
          </StyledIBMTypography>
        </Box>
      </Box>
    </Stack>
  );
}

function MarketVolume ({buyAnalytics, sellAnalytics}){
    const buyMarketVolume = buyAnalytics?.market_volume || null;
    const sellMarketVolume = sellAnalytics?.market_volume || null;

    const marketVolume = buyMarketVolume !== null ? buyMarketVolume : sellMarketVolume;

    let message = '';
    let color = '';
    let tag = '';

    if (marketVolume < 0.5) {
      message = 'Market volume is much lower than expected, trades may experience higher impact.';
      color = theme.palette.error.main;
      tag = 'Low';
    } else if (marketVolume < 0.75) {
      message = 'Market volume is lower than expected.';
      color = theme.palette.warning.main;
      tag = 'Below Average';
    } else if (marketVolume < 1) {
      message = 'Market volume is as expected.';
      color = theme.palette.text.primary;
      tag = 'Normal';
    } else if (marketVolume < 1.5) {
      message = 'Market volume is elevated, favorable market conditions.';
      color = theme.palette.success.main;
      tag = 'Above Average';
    } else {
      message =
        'Market volume is very elevated, favorable market conditions to trade quickly, ' +
        'but be mindful of volatility.';
      color = theme.palette.success.main;
      tag = 'High';
    }
    if (marketVolume === null) {
      return (
        <PreTradeAnalyticsCard>
          <MarketVolumeNA />
        </PreTradeAnalyticsCard>
      );
    }
    return (
      <PreTradeAnalyticsCard>
        <MarketVolumeData
          color={color}
          marketVolume={marketVolume}
          message={message}
          tag={tag}
        />
      </PreTradeAnalyticsCard>
    );
  }

function RenderUnavailablePOVAnalytics({ dataUnavailableMessage }) {
  return (
    <Stack
      flexDirection="column"
      justifyContent="space-between"
      minHeight="40px"
      spacing={0.5}
    >
      <Box sx={{ padding: '8px' }}>
        <Box>
          <TreadTooltip
            labelTextVariant="h6"
            placement="top"
            title={
              <div>
                <Typography sx={{ marginBottom: 1.5 }}>
                Shows the proportion of the predicted market volume your order is expected to take up during its duration across your pairs aggregated for this leg.
                </Typography>
                <Typography>{dataUnavailableMessage()}</Typography>
              </div>
            }
            variant="participation_rate"
          />
          </Box>
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <Typography
              sx={{
                fontWeight: 600,
                textDecorationColor: 'rgba(255,255,255,0.5)',
              }}
              variant="h6"
            >
              N/A
            </Typography>
        </Box>
      </Box>
    </Stack>
  );
}

function RenderUnavailableVolatilityAnalytics() {
  return (
    <Stack
      flexDirection="column"
      justifyContent="space-between"
      minHeight="40px"
      spacing={0.5}
    >
      <Box sx={{ padding: '8px' }}>
        <Box>
          <TreadTooltip
            labelTextVariant="h6"
            placement="top"
            title={
              <div>
                <Typography>Shows the expected price movement during the order, calculated as a notional-weighted average of realized volatility across all orders in this leg.</Typography>
              </div>
            }
            variant="market_volatility"
          />
          </Box>
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <Typography
              sx={{
                fontWeight: 600,
                textDecorationColor: 'rgba(255,255,255,0.5)',
              }}
              variant="h6"
            >
              N/A
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
}


function RenderUnavailableVolumeAnalytics({ dataUnavailableMessage }) {
  return (
    <Stack
      flexDirection="column"
      justifyContent="space-between"
      minHeight="40px"
      spacing={0.5}
    >
      <Box sx={{ padding: '8px' }}>
        <Box>
          <TreadTooltip
            labelTextVariant="h6"
            placement="top"
            title={
              <div>
                <Typography sx={{ marginBottom: 1.5 }}>
                Shows the aggregated market activity across this leg over the past 24 hours relative to expected seasonal volume.
                </Typography>
                <Typography>{dataUnavailableMessage()}</Typography>
              </div>
            }
            variant="market_volume"
          />
        </Box>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Typography
            sx={{
              fontWeight: 600,
              textDecorationColor: 'rgba(255,255,255,0.5)',
            }}
            variant="h6"
          >
            N/A
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
}

function PreTradeAnalyticsMultiComponent({ buyData, sellData, loading, dataError }) {
  const buyAnalytics = buyData || {};
  const sellAnalytics = sellData || {};
  const isBuyDataAvailable = buyData && Object.keys(buyData).length > 0;
  const isSellDataAvailable = sellData && Object.keys(sellData).length > 0;
  const isDataAvailable = isBuyDataAvailable || isSellDataAvailable;

  const renderContent = () => {
    if (loading) {
      return (
        <Box
          alignItems='center'
          display='flex'
          flexDirection='column'
          minHeight='110px'
        >
          <Box
            alignItems='center'
            display='flex'
            flexGrow={1}
            height='100%'
            justifyContent='center'
            sx={{ marginTop: '12px' }}
          >
            <CircularProgress />
          </Box>
        </Box>
      );
    }

    const dataUnavailableMessage = () => {
      if (dataError) {
        return 'Market data unavailable for selected exchange and pair.';
      }
      return 'Input a valid order to view pre-trade analytics.';
    };


    if (!isDataAvailable) {
      return (
        <Box>
          <Grid container mt={2} spacing={1}>
            <Grid item xs={4}>
              <PreTradeAnalyticsCard>
                <RenderUnavailablePOVAnalytics
                  dataUnavailableMessage={dataUnavailableMessage}
                />
              </PreTradeAnalyticsCard>
            </Grid>
            <Grid item xs={4}>
              <PreTradeAnalyticsCard>
                <RenderUnavailableVolatilityAnalytics/>
              </PreTradeAnalyticsCard>
            </Grid>
            <Grid item xs={4}>
              <PreTradeAnalyticsCard>
                <RenderUnavailableVolumeAnalytics
                  dataUnavailableMessage={dataUnavailableMessage}
                />
              </PreTradeAnalyticsCard>
            </Grid>
          </Grid>
        </Box>
      );
    }

    return (
      <Box>
        <Grid container mt={2} spacing={1}>
          <Grid item xs={4}>
            <PreTradeAnalyticsCard>
              <ParticipationRate
                buyAnalytics={buyAnalytics}
                sellAnalytics={sellAnalytics}
              />
            </PreTradeAnalyticsCard>
          </Grid>
          <Grid item xs={4}>
            <PreTradeAnalyticsCard>
              <MarketVolatility
                buyAnalytics={buyAnalytics}
                sellAnalytics={sellAnalytics}
              />
            </PreTradeAnalyticsCard>
          </Grid>
          <Grid item xs={4}>
            <PreTradeAnalyticsCard>
              <MarketVolume
                buyAnalytics={buyAnalytics}
                sellAnalytics={sellAnalytics}
              />
            </PreTradeAnalyticsCard>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return renderContent();
}

export default PreTradeAnalyticsMultiComponent;