import React from 'react';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import { Loader } from '@/shared/Loader';
import { DateTime } from 'luxon';
import { prettyPrice } from '@/util';
import DataComponent from '@/shared/DataComponent';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import {
  StyledCardTableCell,
  StyledHeaderTableCellWithLine,
  StyledTableCell,
  formatDateTime,
} from '../orderTable/util';

const getColumns = (type) => {
  switch (type) {
    case 'detail':
      return [
        {
          id: 'created_at',
          label: 'Time',
          align: 'left',
          isNotNumber: true,
        },
        { id: 'qty', label: 'Quantity', align: 'right' },
        { id: 'price', label: 'Price($)', align: 'right' },
        {
          id: 'placement_type',
          label: 'Type',
          align: 'center',
          isNotNumber: true,
        },
        {
          id: 'pct_filled',
          label: 'Filled',
          align: 'center',
          isNotNumber: true,
        },
      ];

    case 'simple':
      return [
        {
          id: 'created_at',
          label: 'Time',
          align: 'left',
          isNotNumber: true,
        },
        {
          id: 'exchange',
          label: 'Exchange',
          align: 'right',
          isNotNumber: true,
        },
        { id: 'qty', label: 'Quantity', align: 'right' },
        { id: 'price', label: 'Price($)', align: 'right' },
        {
          id: 'placement_type',
          label: 'Type',
          align: 'center',
          isNotNumber: true,
        },
        {
          id: 'pct_filled',
          label: 'Filled',
          align: 'center',
          isNotNumber: true,
        },
      ];
    case 'fills':
      return [
        {
          id: 'time',
          label: 'Time',
          align: 'left',
          isNotNumber: true,
        },
        {
          id: 'external_id',
          label: 'External ID',
          align: 'right',
          isNotNumber: true,
        },
        { id: 'qty', label: 'Quantity', align: 'right' },
        { id: 'price', label: 'Price($)', align: 'right' },
        {
          id: 'role',
          label: 'Role',
          align: 'center',
          isNotNumber: true,
        },
      ];
    default:
      return [
        { id: 'id', label: 'ID', align: 'left', isNotNumber: true },
        {
          id: 'created_at',
          label: 'Time',
          align: 'left',
          isNotNumber: true,
        },
        {
          id: 'placement_type',
          label: 'Type',
          align: 'center',
          isNotNumber: true,
        },
        {
          id: 'status',
          label: 'Status',
          align: 'center',
          isNotNumber: true,
        },
        { id: 'qty', label: 'Qty', align: 'right' },
        { id: 'executed_qty', label: 'Ex Qty', align: 'right' },
        { id: 'price', label: 'Price($)', align: 'right' },
        { id: 'base_qty', label: 'Base Qty', align: 'right' },
        {
          id: 'executed_base_qty',
          label: 'Base Ex Qty',
          align: 'right',
        },
        { id: 'passiveness', label: 'Passiveness', align: 'center' },
      ];
  }
};

const pctFilledColor = (pctFilled) => {
  if (pctFilled === 100) {
    return 'success.main';
  }
  if (pctFilled === 0) {
    return 'text.secondary';
  }
  return 'primary.main';
};

function OrderDetailTable({
  title,
  data,
  dataLoading,
  paginationProps = {},
  isSimpleOrderView = false,
  isDetailOrderView = false,
  isFillsView = false,
}) {
  const {
    totalRows,
    currentPageNumber,
    setCurrentPageNumber,
    currentPageSize,
  } = paginationProps;

  const theme = useTheme();

  const getType = () => {
    if (isSimpleOrderView) {
      return 'simple';
    }
    if (isDetailOrderView) {
      return 'detail';
    }
    if (isFillsView) {
      return 'fills';
    }
    return 'default';
  };

  const columns = getColumns(getType());

  const StyledCell = isDetailOrderView ? StyledCardTableCell : StyledTableCell;

  const cellStyles = {
    padding: '8px',
  };

  const headerStyles = {
    padding: '8px',
    backgroundColor: isDetailOrderView
      ? theme.palette.background.card
      : theme.palette.background.container,
  };

  const statusColor = (status) => {
    switch (status) {
      case 'ACTIVE':
        return 'success.main';
      case 'FILLED':
        return 'success.main';
      case 'FAILED':
        return 'error.main';
      case 'MISSING':
        return 'error.main';
      case 'CANCELLED':
        return 'primary.main';
      default:
        return 'text.offWhite';
    }
  };

  const typeColor = (type) => {
    switch (type) {
      case 'MAKE':
        return 'success.main';
      case 'TAKE':
        return 'error.main';
      default:
        return 'text.offWhite';
    }
  };

  const getColumnWidth = (columnId) => {
    switch (columnId) {
      case 'created_at':
        return 2; // 2x base width
      case 'qty':
      case 'price':
        return 1.5; // 1.5x base width
      case 'placement_type':
      case 'pct_filled':
        return 0.8; // 0.8x base width
      default:
        return 1; // base width
    }
  };

  const renderHeaderRow = () => {
    const totalWeights = columns.reduce(
      (sum, column) => sum + getColumnWidth(column.id),
      0
    );
    const baseWidth = 100 / totalWeights;

    return (
      <TableRow>
        {columns.map((column) => (
          <StyledHeaderTableCellWithLine
            align={column.align}
            key={`${column.id}_header`}
            style={headerStyles}
            width={`${baseWidth * getColumnWidth(column.id)}%`}
          >
            <Typography variant='small2'>{column.label}</Typography>
          </StyledHeaderTableCellWithLine>
        ))}
      </TableRow>
    );
  };

  const renderRow = (p) => {
    return (
      <TableRow>
        {columns.map((column) => {
          switch (column.id) {
            case 'created_at':
              return (
                <StyledCell
                  align={column.align}
                  key={`${column.id}_bodyrow`}
                  style={cellStyles}
                >
                  <Typography variant='small2'>
                    {formatDateTime(p.created_at)}
                  </Typography>
                </StyledCell>
              );
            case 'time':
              return (
                <StyledCell
                  align={column.align}
                  key={`${column.id}_bodyrow`}
                  style={cellStyles}
                >
                  <Typography variant='small2'>
                    {formatDateTime(p.time)}
                  </Typography>
                </StyledCell>
              );
            case 'placement_type':
              return (
                <StyledCell
                  align={column.align}
                  key={`${column.id}_bodyrow`}
                  style={cellStyles}
                >
                  <Typography
                    color={typeColor(p.placement_type)}
                    variant='small2'
                  >
                    {p.placement_type}
                  </Typography>
                </StyledCell>
              );
            case 'role':
              return (
                <StyledCell
                  align={column.align}
                  key={`${column.id}_bodyrow`}
                  style={cellStyles}
                >
                  <Typography color={typeColor(p.role)}>{p.role}</Typography>
                </StyledCell>
              );
            case 'status':
              return (
                <StyledCell
                  align={column.align}
                  key={`${column.id}_bodyrow`}
                  style={cellStyles}
                >
                  <Typography color={statusColor(p.status)} variant='small2'>
                    {p.status}
                  </Typography>
                </StyledCell>
              );
            case 'pct_filled': {
              const formattedPctFilled = Math.round(Number(p.pct_filled));

              return (
                <StyledCell
                  align={column.align}
                  key={`${column.id}_bodyrow`}
                  style={cellStyles}
                >
                  <Typography
                    color={pctFilledColor(formattedPctFilled)}
                    variant='small2'
                  >
                    {formattedPctFilled}%
                  </Typography>
                </StyledCell>
              );
            }
            case 'exchange': {
              return (
                <StyledCell
                  align={column.align}
                  key={`${column.id}_bodyrow`}
                  style={cellStyles}
                >
                  <Typography variant='small2'>{p.exchange}</Typography>
                </StyledCell>
              );
            }

            default: {
              const value = p[column.id];
              return column.isNotNumber ? (
                <StyledCell
                  align={column.align}
                  key={`${column.id}_bodyrow`}
                  style={cellStyles}
                >
                  <Typography variant='small2'>{value}</Typography>
                </StyledCell>
              ) : (
                <StyledCell
                  align={column.align}
                  key={`${column.id}_bodyrow`}
                  style={cellStyles}
                >
                  <Typography
                    variant={
                      column.id === 'price' || column.id === 'qty'
                        ? 'small1'
                        : 'small2'
                    }
                  >
                    {value ? prettyPrice(value, 2) : '-'}
                  </Typography>
                </StyledCell>
              );
            }
          }
        })}
      </TableRow>
    );
  };

  return (
    <Stack direction='column' sx={{ height: '100%' }}>
      {!isDetailOrderView && (
        <Typography color='text.offWhite' sx={{ p: 2 }} variant='subtitle2'>
          {title}
        </Typography>
      )}
      <DataComponent
        emptyComponent={
          <Box
            alignItems='center'
            display='flex'
            height='100%'
            justifyContent='center'
          >
            <Typography align='center' variant='body3'>
              No {title}
            </Typography>
          </Box>
        }
        isEmpty={data?.length === 0}
        isLoading={dataLoading}
        loadingComponent={<Loader />}
      >
        <Stack
          direction='column'
          justifyContent='space-between'
          sx={{ height: '100%' }}
        >
          <TableContainer>
            <Table stickyHeader>
              <TableHead>{renderHeaderRow()}</TableHead>
              <TableBody>{data.map((p) => renderRow(p))}</TableBody>
            </Table>
          </TableContainer>
          {paginationProps.length > 0 && (
            <TablePagination
              count={totalRows}
              page={currentPageNumber}
              rowsPerPage={currentPageSize}
              rowsPerPageOptions={[]}
              sx={{
                border: 0,
                borderTop: '1px solid rgba(255, 255, 255, 0.12)',
                overflow: 'visible',
              }}
              onPageChange={(e, newPage) => setCurrentPageNumber(newPage)}
            />
          )}
        </Stack>
      </DataComponent>
    </Stack>
  );
}

export default OrderDetailTable;
