export const marketOptionDisplayValue = (option) => {
  const displayMessages = {
    spot: 'Spot',
    usd_futures: 'Linear Futures',
    coin_futures: 'Inverse Futures',
    options: 'Options',
    otc: 'OTC',
  };

  return displayMessages[option] || option;
};
