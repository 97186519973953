import { useTheme } from '@mui/system';

function usePreTradeAnalytics(preTradeEstimationData) {
  const theme = useTheme();

  const { pov, volatility } = preTradeEstimationData;
  const displayPov = pov ? `${Number(pov).toFixed(4)}%` : 'N/A';
  const displayVolatility = volatility
    ? `±${Number(volatility).toFixed(4)}%`
    : 'N/A';

  let displayPovColor;
  if (!pov) {
    displayPovColor = '';
  } else if (pov < 0.5) {
    displayPovColor = theme.palette.success.main;
  } else if (pov < 1) {
    displayPovColor = theme.palette.warning.main;
  } else {
    displayPovColor = theme.palette.error.main;
  }
  return { displayPov, displayPovColor, displayVolatility };
}

export default usePreTradeAnalytics;
