import { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { getPairPrice, getPreTradeAnalytics } from '@/apiServices';
import { useOrderForm } from '@/shared/context/OrderFormProvider';
import { ErrorContext } from '@/shared/context/ErrorProvider';
import { Api } from '@mui/icons-material';

function usePreTradeAnalytics({ orderItems, selectedDuration }) {
  const {
    preTradeEstimationData,
    setPreTradeEstimationData,
    preTradeDataLoading,
    setPreTradeDataLoading,
    preTradeDataError,
    setPreTradeDataError,
  } = useOrderForm();

  const { setHasError, setErrorContent } = useContext(ErrorContext);

  const showAlert = ({ severity, message }) => {
    setErrorContent({ severity, message });
    setHasError(true);
  };

  const [fetchPairAttempts, setFetchPairAttempts] = useState(0);
  const [selectedPairPrice, setSelectedPairPrice] = useState({ pair: '', price: 0 });
  const previousValidOrderItemsRef = useRef([]);
  const previousValidOrderCountRef = useRef(0);

  const fetchPairPrice = useCallback(async () => {
    const allOrderItems = [
      ...(orderItems.Multi?.[0]?.Buy || []),
      ...(orderItems.Multi?.[0]?.Sell || []),
      ...(orderItems.single || []), // Include single if applicable, need to refactor OrderEntryForm for it
    ];

    const orderItem = allOrderItems.find(
      (item) => item.accounts.length > 0 && item.pair?.id && item.qty
    );

    if (
      fetchPairAttempts > 2 ||
      !orderItem?.pair?.id ||
      orderItem.accounts.length === 0
    ) {
      console.warn("No valid order item found for fetching price.");
      return null;
    }

    let pairPrice = selectedPairPrice.price;
    const selectedPairName = orderItem.pair.id;
    const {exchangeName} = orderItem.accounts[0];

    if (selectedPairPrice.pair !== selectedPairName) {
      try {
        const result = await getPairPrice(selectedPairName, exchangeName);
        pairPrice = result[selectedPairName];
      } catch (error) {
        console.error(`Failed to fetch price for pair ${selectedPairName}:`, error);
        setFetchPairAttempts(fetchPairAttempts + 1);
        return null;
      }
      setFetchPairAttempts(0);
      setSelectedPairPrice({ pair: selectedPairName, price: pairPrice });
    }

    return pairPrice;
  }, [orderItems, fetchPairAttempts, selectedPairPrice]);

  const fetchTradePrediction = useCallback(async () => {
    const allBuyOrders = orderItems.Multi?.[0]?.Buy || [];
    const allSellOrders = orderItems.Multi?.[0]?.Sell || [];
    const allSingleOrders = orderItems.single || [];

    const validBuyOrders = allBuyOrders.filter(
      (item) =>
        Array.isArray(item.accounts) &&
        item.accounts.length > 0 &&
        item.pair?.id &&
        item.qty
    );

    const validSellOrders = allSellOrders.filter(
      (item) =>
        Array.isArray(item.accounts) &&
        item.accounts.length > 0 &&
        item.pair?.id &&
        item.qty
    );

    const validSingleOrders = allSingleOrders.filter(
      (item) =>
        Array.isArray(item.accounts) &&
        item.accounts.length > 0 &&
        item.pair &&
        item.qty
    );

    const validOrderItems = [...validBuyOrders, ...validSellOrders, ...validSingleOrders];
    const validOrderCount = validOrderItems.length;
    const previousValidOrderCount = previousValidOrderCountRef.current;
    const hasValidOrders = validOrderCount > 0;

    const hasOrderChanged = () => {
      if (previousValidOrderItemsRef.current.length !== validOrderItems.length) {
        return true;
      }
      return validOrderItems.some((order, index) => {
        const previousOrder = previousValidOrderItemsRef.current[index];
        const isSingleOrder = !order.pair?.id && typeof order.pair === 'string';
        return (
          (isSingleOrder && order.pair !== previousOrder?.pair) ||
          (!isSingleOrder && order.pair?.id !== previousOrder?.pair?.id) ||
          order.qty !== previousOrder?.qty ||
          JSON.stringify(order.accounts) !== JSON.stringify(previousOrder?.accounts)
        );
      });
    };


    if (validOrderCount === previousValidOrderCount && !hasOrderChanged()) {
      return;
    }

    previousValidOrderCountRef.current = validOrderCount;
    previousValidOrderItemsRef.current = validOrderItems;

    if (!hasValidOrders) {
      setPreTradeEstimationData(null);
      return;
    }

  try {
    setPreTradeDataLoading(true);
      const predictionPromises = validOrderItems
        .filter((item) => item?.pair?.id && item?.accounts && item.qty)
        .map(async (item) => {
          const pair = item.pair.id;
          const exchangeName = item.accounts[0]?.exchangeName;
          const price = await fetchPairPrice(pair, exchangeName);
        if (!price) {
          console.warn(`Price not fetched for pair: ${pair}`);
          return null;
        }
        const data = await getPreTradeAnalytics(orderItems, selectedDuration, price);
        return data;
      });

    const predictions = (await Promise.all(predictionPromises)).filter(Boolean);
    let formattedData = {};
    predictions.forEach((prediction) => {
      if ("Multi" in prediction) {
        const { Buy, Sell } = prediction.Multi[0];
        formattedData = {
          Buy: {
            pov: Buy?.pov ?? null,
            volatility: Buy?.volatility ?? null,
            market_volume: Buy?.market_volume ?? null,
          },
          Sell: {
            pov: Sell?.pov ?? null,
            volatility: Sell?.volatility ?? null,
            market_volume: Sell?.market_volume ?? null,
          },
        };
      } else if ("single" in prediction) {
        const single = prediction.single[0];
        formattedData = {
          pov: single?.pov ?? null,
          volatility: single?.volatility ?? null,
          market_volume: single?.market_volume ?? null,
        };
      }
    });
    setPreTradeEstimationData(formattedData);
    } catch (error) {
      setPreTradeDataError(error.message);
      showAlert({
        severity: 'error',
        message: `Failed to fetch pre-trade prediction: ${error.message}`,
      });
    } finally {
      setPreTradeDataLoading(false);
    }
  }, [orderItems, fetchPairPrice, selectedDuration]);

  useEffect(() => {
    fetchTradePrediction();
  }, [orderItems]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchTradePrediction();
    }, 1500);

    return () => {
      clearTimeout(handler);
    };
  }, [selectedDuration]);

  return {
    fetchTradePrediction,
    preTradeEstimationData,
    preTradeDataLoading,
    preTradeDataError,
  };
}

export default usePreTradeAnalytics;
