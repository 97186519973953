import { AccountsProvider } from '@/shared/context/AccountsProvider';
import { ErrorContext } from '@/shared/context/ErrorProvider';
import { Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useContext } from 'react';
import ExplorerProofsTable from './ExplorerProofsTable';
import ExplorerTradesTable from './ExplorerTradesTable';

const columns = [
  { id: 'id', label: 'Transaction ID', width: 260, align: 'left' },
  { id: 'exchange_name', label: 'Exchange', width: 50, align: 'left' },
  { id: 'trader_id', label: 'User', width: 60, align: 'left' },
  { id: 'epoch', label: 'Epoch', width: 30, align: 'left' },
  { id: 'merkle_root', label: 'Hash', width: 80, align: 'left' },
  { id: 'usd_volume', label: 'Volume', width: 30, align: 'right' },
];

function insertEllipsis(str, startSlice = 4, endSlice = 3) {
  if (!str) {
    return '';
  }

  if (str.length <= 7) {
    return str; // No need to shorten
  }

  const start = str.slice(0, startSlice);
  const end = str.slice(-1 * endSlice);

  return `${start}...${end}`;
}

function ExplorerPageContent() {
  const isPreviewOnly = true; // because we are not showing the full table
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        marginX: 1,
      }}
    >
      <div style={{ padding: '1rem 0' }}>
        <Box>
          <Typography
            fontFamily='Jost'
            fontSize={28}
            fontWeight={400}
            variant='h2'
          >
            On-Chain Explorer
          </Typography>
        </Box>
      </div>
      <Stack direction='row' height='calc(100% - 32px)' spacing={2}>
        <Box
          sx={{
            textAlign: 'left',
            width: 'calc(50% - 16px)',
          }}
        >
          <ExplorerTradesTable isPreviewOnly={isPreviewOnly} />
        </Box>
        <Box
          sx={{
            textAlign: 'right',
            width: 'calc(50% - 16px)',
          }}
        >
          <ExplorerProofsTable isPreviewOnly={isPreviewOnly} />
        </Box>
      </Stack>
    </Box>
  );
}

export default function ExplorerPage() {
  return (
    <AccountsProvider>
      <ExplorerPageContent />
    </AccountsProvider>
  );
}
