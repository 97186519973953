import React from 'react';
import {
  Accordion,
  AccordionSummary,
  Typography,
  Collapse,
  AccordionDetails,
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

export function DashboardAccordianComponent({
  isOpen,
  setIsOpen,
  isAlgo,
  title,
  children,
}) {
  return (
    <Accordion
      expanded={isOpen}
      sx={{
        '&.MuiAccordion-root': {
          backgroundColor: 'none',
        },
        '& .MuiCollapse-vertical': {
          backgroundColor: 'card.main',
        },
      }}
    >
      <AccordionSummary
        aria-controls='panel1-content'
        expandIcon={isOpen ? <Remove /> : <Add />}
        sx={{
          height: 40, // Fixed height
          minHeight: 40, // Prevent changing height on expand
          '&.Mui-expanded': {
            height: 40,
            minHeight: 40,
          },

          '& .MuiAccordionSummary-content': {
            margin: 0, // Remove margin adjustment when expanded
            '&.Mui-expanded': {
              margin: 0,
            },
          },
          backgroundColor: isOpen ? 'secondary.dark' : 'grey.dark2',
          '&:hover': {
            backgroundColor: isOpen ? 'secondary.dark' : 'grey.dark',
          },
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Typography variant='body1'>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          marginTop: '1rem',
          paddingX: '0',
        }}
      >
        <Collapse in={isAlgo}>{children}</Collapse>
      </AccordionDetails>
    </Accordion>
  );
}

export const getStrategyObjectSafe = (strategies, key) => {
  if (strategies && key && strategies[key]) {
    return strategies[key];
  }
  return {};
};

export const TRAJECTORIES_WITH_LIMIT_PRICE = ['Limit', 'Iceberg', 'IOC'];

export const OrderEntryType = {
  MANUAL: { key: 'MANUAL', label: 'Manual' },
  AUTO: { key: 'AUTO', label: 'Auto (BETA)' },
};
