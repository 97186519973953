import React from 'react';
import { theme } from '@/theme/theme';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  ThemeProvider,
  IconButton,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Stack } from '@mui/system';
import OrderInfo from '@/shared/orderDetail/OrderInfo';
import OrderAccounts from '@/shared/orderDetail/OrderAccounts';
import { useOrderForm } from '@/shared/context/OrderFormProvider';
import getBaseTokenIcon from '@images/tokens';
import { capitalize, prettyPrice, numberWithCommas } from '@/util';
import { timezoneAtom } from '@/shared/hooks/useGlobalFormReducer';
import { useAtom } from 'jotai';
import { timeZoneNoOffset } from '@/shared/TimezoneUtil';
import { DateTime } from 'luxon';
import CloseIcon from '@mui/icons-material/Close';
import usePreTradeAnayltics from './hooks/usePreTradeAnalytics';

function Summary({
  isSimple,
  pairDisplayIcon,
  selectedPair,
  base,
  quote,
  selectedSide,
  accounts,
  selectedAccounts,
  targetQuantity,
  targetNotional,
  strategy,
  limitPrice,
  selectedDuration,
  orderSlices,
  isAuto,
  urgency,
}) {
  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Stack alignItems='center' direction='row' spacing={1}>
          <img
            alt='Token Icon'
            src={pairDisplayIcon}
            style={{ height: '20px', width: '20px' }}
          />
          <Typography variant='h6'>{selectedPair}</Typography>
          <Typography variant='h6'>-</Typography>
          <Typography color={`side.${selectedSide}`} variant='h6'>
            {capitalize(selectedSide)}
          </Typography>
        </Stack>
      </Grid>
      <Grid xs={3}>
        <OrderInfo label='Target Quantity'>
          <Typography variant='subtitle1'>
            {prettyPrice(targetQuantity)} {base}
          </Typography>
        </OrderInfo>
      </Grid>
      <Grid xs={3}>
        <OrderInfo label='Target Notional'>
          <Typography variant='subtitle1'>
            {prettyPrice(targetNotional, 2)} {quote}
          </Typography>
        </OrderInfo>
      </Grid>
      <Grid xs={6}>
        <OrderInfo label='Accounts'>
          <OrderAccounts
            accounts={accounts}
            selectedAccounts={selectedAccounts}
          />
        </OrderInfo>
      </Grid>
      {!isAuto && (
        <>
          <Grid xs={3}>
            <OrderInfo label='Strategy'>
              <Typography variant='body3'>{strategy.name}</Typography>
            </OrderInfo>
          </Grid>
          {limitPrice && (
            <Grid xs={3}>
              <OrderInfo label='Limit Price'>
                <Typography variant='body3'>
                  {prettyPrice(limitPrice)} {quote}
                </Typography>
              </OrderInfo>
            </Grid>
          )}
          <Grid xs={3}>
            <OrderInfo label={isSimple ? 'Expiry' : 'Duration'}>
              <Typography variant='body3'>
                {prettyPrice(selectedDuration / 60, 2)} min(s)
              </Typography>
            </OrderInfo>
          </Grid>
          {strategy.name === 'Iceberg' && (
            <Grid xs={3}>
              <OrderInfo label='Slices'>
                <Typography variant='body3'>{orderSlices}</Typography>
              </OrderInfo>
            </Grid>
          )}
        </>
      )}
      {isAuto && (
        <Grid xs={3}>
          <OrderInfo label='Auto Strategy'>
            <Typography color={`orderUrgency.${urgency}`} variant='body3'>
              {urgency}
            </Typography>
          </OrderInfo>
        </Grid>
      )}
    </Grid>
  );
}

function SimpleStrategyConfiguration({
  isLimit,
  isReduceOnly,
  stopPrice,
  quote,
  updatePairLeverage,
}) {
  return (
    <>
      <Grid xs={3}>
        <OrderInfo label='Active Limit'>
          <Typography variant='body3'>{isLimit ? 'Yes' : 'No'}</Typography>
        </OrderInfo>
      </Grid>
      <Grid xs={3}>
        <OrderInfo label='Reduce Only'>
          <Typography variant='body3'>{isReduceOnly ? 'Yes' : 'No'}</Typography>
        </OrderInfo>
      </Grid>
      {stopPrice && (
        <Grid xs={3}>
          <OrderInfo label='Stop Price'>
            <Typography variant='body3'>
              {prettyPrice(stopPrice, 2)} {quote}
            </Typography>
          </OrderInfo>
        </Grid>
      )}
      {updatePairLeverage && (
        <Grid xs={3}>
          <OrderInfo label='Pair Leverage'>
            <Typography variant='body3'>{updatePairLeverage}</Typography>
          </OrderInfo>
        </Grid>
      )}
    </>
  );
}

function AlgoStrategyConfiguration({
  trajectory,
  passiveness,
  discretion,
  maxClipSize,
  alphaTilt,
  maxOtcPercentage,
  isPassiveOnly,
  isLimit,
  isReduceOnly,
  isStrictDuration,
  updatePairLeverage,
  povLimit,
  povTarget,
}) {
  return (
    <>
      <Grid xs={3}>
        <OrderInfo label='Trajectory'>
          <Typography variant='body3'>{trajectory}</Typography>
        </OrderInfo>
      </Grid>
      <Grid xs={3}>
        <OrderInfo label='Passiveness'>
          <Typography variant='body3'>{passiveness}</Typography>
        </OrderInfo>
      </Grid>
      <Grid xs={3}>
        <OrderInfo label='Discretion'>
          <Typography variant='body3'>{discretion}</Typography>
        </OrderInfo>
      </Grid>
      <Grid xs={3}>
        <OrderInfo label='Alpha Tilt'>
          <Typography variant='body3'>{alphaTilt}</Typography>
        </OrderInfo>
      </Grid>
      <Grid xs={3}>
        <OrderInfo label='Max Clip Size'>
          <Typography variant='body3'>
            {numberWithCommas(maxClipSize)}
          </Typography>
        </OrderInfo>
      </Grid>
      <Grid xs={3}>
        <OrderInfo label='Max OTC Percentage'>
          <Typography variant='body3'>{maxOtcPercentage}</Typography>
        </OrderInfo>
      </Grid>
      <Grid xs={3}>
        <OrderInfo label='Passive Only'>
          <Typography variant='body3'>
            {isPassiveOnly ? 'Yes' : 'No'}
          </Typography>
        </OrderInfo>
      </Grid>
      <Grid xs={3}>
        <OrderInfo label='Active Limit'>
          <Typography variant='body3'>{isLimit ? 'Yes' : 'No'}</Typography>
        </OrderInfo>
      </Grid>
      <Grid xs={3}>
        <OrderInfo label='Reduce Only'>
          <Typography variant='body3'>{isReduceOnly ? 'Yes' : 'No'}</Typography>
        </OrderInfo>
      </Grid>
      <Grid xs={3}>
        <OrderInfo label='Strict Duration'>
          <Typography variant='body3'>
            {isStrictDuration ? 'Yes' : 'No'}
          </Typography>
        </OrderInfo>
      </Grid>
      {updatePairLeverage && (
        <Grid xs={3}>
          <OrderInfo label='Pair Leverage'>
            <Typography variant='body3'>{updatePairLeverage}</Typography>
          </OrderInfo>
        </Grid>
      )}
      {povTarget && (
        <Grid xs={3}>
          <OrderInfo label='Participation Rate Target'>
            <Typography variant='body3'>{povTarget * 100}%</Typography>
          </OrderInfo>
        </Grid>
      )}
      {povLimit && (
        <Grid xs={3}>
          <OrderInfo label='Participation Rate Limit'>
            <Typography variant='body3'>{povLimit * 100}%</Typography>
          </OrderInfo>
        </Grid>
      )}
    </>
  );
}

function AutoStrategyConfiguration({
  strategy,
  duration,
  passiveness,
  alphaTilt,
}) {
  const isMarket = strategy === 'Market';
  return (
    <>
      <Grid xs={3}>
        <OrderInfo label='Strategy'>
          <Typography variant='body3'>{strategy}</Typography>
        </OrderInfo>
      </Grid>
      {!isMarket && (
        <>
          <Grid xs={3}>
            <OrderInfo label='Duration'>
              <Typography variant='body3'>
                {prettyPrice(duration / 60, 2)} min(s)
              </Typography>
            </OrderInfo>
          </Grid>
          <Grid xs={3}>
            <OrderInfo label='Passiveness'>
              <Typography variant='body3'>{passiveness}</Typography>
            </OrderInfo>
          </Grid>
          <Grid xs={3}>
            <OrderInfo label='Alpha Tilt'>
              <Typography variant='body3'>{alphaTilt}</Typography>
            </OrderInfo>
          </Grid>
        </>
      )}
    </>
  );
}

function Scheduled({ startDatetime, duration }) {
  const [timeZone] = useAtom(timezoneAtom);
  const startDt = DateTime.fromISO(startDatetime, { zone: 'utc' }).setZone(
    timeZoneNoOffset(timeZone)
  );
  const endDt = startDt.plus({ seconds: duration });

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Typography color='secondary' variant='small1'>
          Scheduled
        </Typography>
      </Grid>
      <Grid xs={6}>
        <OrderInfo label='Time start'>
          <Typography variant='body3'>
            {startDt.toFormat('yyyy-MM-dd HH:mm')}
          </Typography>
        </OrderInfo>
      </Grid>
      <Grid xs={6}>
        <OrderInfo label='Time end'>
          <Typography variant='body3'>
            {endDt.toFormat('yyyy-MM-dd HH:mm')}
          </Typography>
        </OrderInfo>
      </Grid>
      <Grid xs={6}>
        <OrderInfo label='Timezone'>
          <Typography variant='body3'>{timeZone}</Typography>
        </OrderInfo>
      </Grid>
    </Grid>
  );
}

function PreTradeAnalytics({ preTradeEstimationData }) {
  const { displayPov, displayPovColor, displayVolatility } =
    usePreTradeAnayltics(preTradeEstimationData);
  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Typography variant='small1'>Pre-Trade Analytics</Typography>
      </Grid>
      <Grid xs={6}>
        <OrderInfo label='Participation Rate'>
          <Typography color={displayPovColor} variant='body3'>
            {displayPov}
          </Typography>
        </OrderInfo>
      </Grid>
      <Grid xs={6}>
        <OrderInfo label='Expected Volatility'>
          <Typography variant='body3'>{displayVolatility}</Typography>
        </OrderInfo>
      </Grid>
    </Grid>
  );
}

function OrderConfirmation({ order, onClose, onConfirm }) {
  const {
    accounts: selectedAccounts,
    alpha_tilt: alphaTilt,
    side: selectedSide,
    duration: selectedDuration,
    engine_passiveness: passiveness,
    schedule_discretion: discretion,
    strategy_params: selectedStrategyParams,
    super_strategy: selectedStrategy,
    strategy: trajectory,
    updated_leverage: updatePairLeverage,
    stop_price: stopPrice,
    max_otc: maxOtcPercentage,
    limit_price: limitPrice,
    quote_asset_qty: quoteQty,
    base_asset_qty: baseQty,
    order_slices: orderSlices,
    pre_trade_data: preTradeEstimationData,
    pair: selectedPair,
    auto_order_metadata: autoOrderMetadata,
    start_datetime: startDatetime,
    pov_limit: povLimit,
    pov_target: povTarget,
  } = order;

  const { initialLoadValue, convertedQty } = useOrderForm();

  const { accounts, trajectories, strategies } = initialLoadValue;
  // Pair
  const [base, quote] = selectedPair.split('-');
  const [token, _] = base.split(':');
  const pairDisplayIcon = getBaseTokenIcon(token);

  // Strategy/Trajectory
  let strategy = strategies[selectedStrategy];
  const isSimple = !strategy?.is_super_strategy;
  let traj = trajectories[trajectory];

  // If simple order, strategy is listed as the trajectory
  if (isSimple) {
    strategy = traj;
    traj = null;
  }

  const isAuto = Object.keys(autoOrderMetadata).length > 0;

  let StrategyConfiguration;
  if (isAuto) {
    StrategyConfiguration = (
      <AutoStrategyConfiguration
        alphaTilt={alphaTilt}
        duration={selectedDuration}
        passiveness={passiveness}
        strategy={strategy.name}
      />
    );
  } else if (isSimple) {
    StrategyConfiguration = (
      <SimpleStrategyConfiguration
        isLimit={!!limitPrice}
        isReduceOnly={selectedStrategyParams?.reduce_only}
        pairLeverage={updatePairLeverage}
        quote={quote}
        stopPrice={stopPrice}
      />
    );
  } else {
    StrategyConfiguration = (
      <AlgoStrategyConfiguration
        alphaTilt={alphaTilt}
        discretion={discretion}
        isLimit={!!limitPrice}
        isPassiveOnly={selectedStrategyParams?.passive_only}
        isReduceOnly={selectedStrategyParams?.reduce_only}
        isStrictDuration={selectedStrategyParams?.strict_duration}
        maxClipSize={selectedStrategyParams?.max_clip_size}
        maxOtcPercentage={maxOtcPercentage}
        passiveness={passiveness}
        povLimit={povLimit}
        povTarget={povTarget}
        trajectory={traj.name}
        updatePairLeverage={updatePairLeverage}
      />
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Card sx={{ width: '800px' }}>
        <CardHeader
          title={
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='space-between'
            >
              <Typography variant='h6'>Order Confirmation</Typography>
              <IconButton aria-label='Close' onClick={onClose}>
                <CloseIcon fontSize='small' />
              </IconButton>
            </Stack>
          }
        />
        <Divider />
        <CardContent>
          <Stack direction='column' spacing={2}>
            <Summary
              accounts={accounts}
              base={base}
              isAuto={isAuto}
              isSimple={isSimple}
              limitPrice={limitPrice}
              orderSlices={orderSlices}
              pairDisplayIcon={pairDisplayIcon}
              quote={quote}
              selectedAccounts={selectedAccounts}
              selectedDuration={selectedDuration}
              selectedPair={selectedPair}
              selectedSide={selectedSide}
              strategy={strategy}
              targetNotional={quoteQty || convertedQty}
              targetQuantity={baseQty || convertedQty}
              urgency={autoOrderMetadata?.urgency}
            />
            <Divider />
            <Grid container spacing={2}>
              <Grid xs={12}>
                <Typography color='secondary' variant='small1'>
                  Strategy Configuration
                </Typography>
              </Grid>
              {StrategyConfiguration}
            </Grid>

            {startDatetime && !isSimple && !isAuto && (
              <>
                <Divider />
                <Scheduled
                  duration={selectedDuration}
                  startDatetime={startDatetime}
                />
              </>
            )}
            <Divider />
            <PreTradeAnalytics
              preTradeEstimationData={preTradeEstimationData}
            />
            <Button
              color={selectedSide === 'buy' ? 'success' : 'error'}
              variant='contained'
              onClick={onConfirm}
            >
              <Typography
                color='text.offBlack'
                style={{ whiteSpace: 'nowrap' }}
              >
                Place {capitalize(selectedSide)} Order
              </Typography>
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </ThemeProvider>
  );
}

export default OrderConfirmation;
