import { AccountsContext } from '@/shared/context/AccountsProvider';
import { matchesTraderId } from '@/shared/cryptoUtil';
import { useTheme } from '@emotion/react';
import {
  Alert,
  Box,
  Card,
  CardContent,
  Grid,
  Icon,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader';
import ICONS from '@images/exchange_icons';
import {
  decryptArweaveTransaction,
  getArweaveData,
  getArweaveTransactionRaw,
} from '../../../apiServices';

// Helper function moved to top
function formatValue(value) {
  if (typeof value === 'object') {
    return JSON.stringify(value);
  }
  return value;
}

function TradeDataDisplay({ data }) {
  if (!data) return null;

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Stack spacing={1}>
              <Box>
                <Typography color='text.secondary' variant='caption'>
                  Price
                </Typography>
                <Typography variant='h6'>
                  {data.price?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Box>

              <Box>
                <Typography color='text.secondary' variant='caption'>
                  Volume
                </Typography>
                <Typography variant='h6'>
                  {data.volume?.toLocaleString()}
                </Typography>
              </Box>
            </Stack>
          </Grid>

          <Grid item md={6} xs={12}>
            <Stack spacing={1}>
              <Box>
                <Typography color='text.secondary' variant='caption'>
                  Trade ID
                </Typography>
                <Typography variant='h6'>{data.trade_id}</Typography>
              </Box>

              <Box>
                <Typography color='text.secondary' variant='caption'>
                  Timestamp
                </Typography>
                <Typography variant='h6'>
                  {new Date(data.timestamp).toLocaleString()}
                </Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function DetailRow({ label, value, isLink }) {
  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xs={3}>
        <Typography color='textSecondary' variant='subtitle1'>
          {label}:
        </Typography>
      </Grid>
      <Grid item xs={9}>
        {isLink ? (
          <Typography variant='body1'>
            <Link href={`//arweave.app/tx/${value}`} target='_blank'>
              {formatValue(value)}
            </Link>
          </Typography>
        ) : (
          <Typography variant='body1'>{formatValue(value)}</Typography>
        )}
      </Grid>
    </Grid>
  );
}

function TradeDetailsPage() {
  const { id } = useParams();
  const [tradeData, setTradeData] = useState(null);
  const [rawData, setRawData] = useState(null);
  const [decryptedData, setDecryptedData] = useState(null);
  const [loading, setLoading] = useState({
    trade: true,
    raw: true,
    decrypted: false,
  });
  const [errors, setErrors] = useState({
    trade: null,
    raw: {
      message: 'Test error message',
      code: 'ERR_123',
      timestamp: new Date().toISOString(),
      details: {
        attempted: true,
        retries: 2,
        lastAttempt: '2023-12-25T12:34:56Z',
      },
    },
    decrypted: null,
  });
  const [isAuthorized, setIsAuthorized] = useState(false);
  const theme = useTheme();
  const { accounts } = useContext(AccountsContext);

  useEffect(() => {
    const fetchTradeData = async () => {
      setLoading((prev) => ({ ...prev, trade: true }));
      setErrors((prev) => ({ ...prev, trade: null }));

      try {
        const graphqlResult = await getArweaveData(1, null, { ids: [id] });

        if (!graphqlResult.edges?.length) {
          setErrors((prev) => ({ ...prev, trade: 'Transaction not found' }));
          return;
        }

        const trade = graphqlResult.edges[0].node;
        const tags = trade.tags.reduce((acc, tag) => {
          acc[tag.name] = tag.value;
          return acc;
        }, {});

        const tradeInfo = { ...trade, ...tags };
        setTradeData(tradeInfo);
      } catch (err) {
        const errorMessage =
          err.response?.data?.error ||
          err.message ||
          'Failed to fetch trade details';
        setErrors((prev) => ({
          ...prev,
          trade: errorMessage,
        }));
      } finally {
        setLoading((prev) => ({ ...prev, trade: false }));
      }
    };

    const fetchRawData = async () => {
      setLoading((prev) => ({ ...prev, raw: true }));
      setErrors((prev) => ({ ...prev, raw: null }));

      try {
        const rawResponse = await getArweaveTransactionRaw(id);
        if (rawResponse?.raw_data) {
          setRawData(rawResponse.raw_data);
        } else {
          throw new Error(
            `Failed to fetch raw transaction data. Got: ${JSON.stringify(rawResponse)}`
          );
        }
      } catch (err) {
        const errorMessage =
          err.response?.data?.error ||
          err.message ||
          'Failed to fetch raw transaction data';
        setErrors((prev) => ({
          ...prev,
          raw: errorMessage,
        }));
      } finally {
        setLoading((prev) => ({ ...prev, raw: false }));
      }
    };

    if (id) {
      fetchTradeData();
      fetchRawData();
    }
  }, [id]);

  useEffect(() => {
    const attemptDecryption = async () => {
      if (!tradeData || !rawData) {
        setErrors((prev) => ({
          ...prev,
          decrypted: 'Missing trade data or raw transaction data',
        }));
        return;
      }

      setLoading((prev) => ({ ...prev, decrypted: true }));
      setErrors((prev) => ({ ...prev, decrypted: null }));

      try {
        const userHasAccess =
          accounts?.some((account) =>
            matchesTraderId(account.hashed_api_key, tradeData.trader_id)
          ) ?? false;
        setIsAuthorized(userHasAccess);

        if (userHasAccess) {
          const decryptResponse = await decryptArweaveTransaction(id, {
            trader_id: tradeData.trader_id,
            raw_data: rawData,
          });

          if (decryptResponse?.decrypted_data) {
            setDecryptedData(decryptResponse.decrypted_data);
          } else {
            throw new Error('Invalid decryption response format');
          }
        }
      } catch (err) {
        const errorMessage =
          err.response?.data?.error ||
          err.message ||
          'Failed to decrypt trade details';
        setErrors((prev) => ({
          ...prev,
          decrypted: errorMessage,
        }));
      } finally {
        setLoading((prev) => ({ ...prev, decrypted: false }));
      }
    };

    if (id && accounts) {
      attemptDecryption();
    }
  }, [id, accounts, tradeData, rawData]);

  if (loading.trade || loading.raw) {
    return (
      <Stack alignItems='center' justifyContent='center' minHeight='80vh'>
        <ScaleLoader color={theme.palette.text.offWhite} />
      </Stack>
    );
  }

  if (errors.trade) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity='error' sx={{ mt: 4, mb: 2 }}>
          Error loading trade details:
          <Box
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              borderRadius: 1,
              maxHeight: '300px',
              overflow: 'auto',
              p: 2,
              mt: 1,
            }}
          >
            <Typography
              component='pre'
              sx={{
                fontFamily: 'monospace',
                m: 0,
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all',
              }}
              variant='body2'
            >
              {JSON.stringify(errors.trade, null, 2)}
            </Typography>
          </Box>
        </Alert>
      </Box>
    );
  }

  if (!tradeData) {
    return (
      <Typography align='center' variant='h6'>
        Trade not found
      </Typography>
    );
  }

  const getErrorContent = (error, msg = 'Error when fetching trade data') => (
    <Alert severity='error' sx={{ mt: 4, mb: 2 }}>
      {msg}
      <Box
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          borderRadius: 1,
          maxHeight: '300px',
          overflow: 'auto',
          p: 2,
        }}
      >
        <Typography
          component='pre'
          sx={{
            fontFamily: 'monospace',
            m: 0,
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-all',
          }}
          variant='body2'
        >
          {JSON.stringify(error, null, 2)}
        </Typography>
      </Box>
    </Alert>
  );

  const getLoadingContent = () => (
    <ScaleLoader color={theme.palette.text.offWhite} />
  );

  const getUnauthorizedContent = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Alert severity='warning' sx={{ mt: 4, mb: 2 }}>
        You are not authorized to view the decrypted data for this trade. Only
        the original trader can decrypt this information.
      </Alert>
      <Typography variant='subtitle1'>
        Comparing your trader ID vs original:
      </Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Typography>
          Your IDs:{' '}
          {accounts.map((acc) => acc.hashed_api_key).join(', ') || 'None'}
        </Typography>
        <Typography>Original ID: {tradeData.trader_id}</Typography>
      </Box>
    </Box>
  );

  const renderRawData = () => {
    if (loading.raw) {
      return getLoadingContent();
    }
    if (errors.raw) {
      return getErrorContent(errors.raw, 'Error when fetching raw data');
    }
    if (rawData) {
      return (
        <Box
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            borderRadius: 1,
            maxHeight: '300px',
            overflow: 'auto',
            p: 2,
          }}
        >
          <Typography
            component='pre'
            sx={{
              fontFamily: 'monospace',
              m: 0,
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-all',
            }}
            variant='body2'
          >
            {rawData}
          </Typography>
        </Box>
      );
    }
    return getErrorContent(
      'Failed to load raw transaction data. Please try again later.'
    );
  };

  const renderDecryptedData = () => {
    if (loading.decrypted) {
      return getLoadingContent();
    }
    if (!isAuthorized) {
      return getUnauthorizedContent();
    }
    if (errors.decrypted) {
      return getErrorContent(errors.decrypted);
    }
    if (decryptedData) {
      return <TradeDataDisplay data={decryptedData} />;
    }
    return getErrorContent('Failed to decrypt data. Please try again later.');
  };

  return (
    <Card
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <CardContent
        sx={{
          flex: 1,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          '&:last-child': { pb: 2 },
        }}
      >
        <DetailRow isLink label='Transaction ID' value={tradeData.id} />
        <DetailRow
          label='Exchange'
          value={
            tradeData.exchange_name.charAt(0).toUpperCase() +
            tradeData.exchange_name.slice(1)
          }
        />
        <DetailRow label='Trader ID' value={tradeData.trader_id} />
        <DetailRow label='Epoch' value={tradeData.epoch} />
        <DetailRow label='Merkle Root' value={tradeData.merkle_root} />

        <Typography sx={{ mt: 4, mb: 2 }} variant='h6'>
          Raw Transaction Data
        </Typography>
        {renderRawData()}

        <Typography sx={{ mt: 4, mb: 2 }} variant='h6'>
          Decrypted Data
        </Typography>
        {renderDecryptedData()}
      </CardContent>
    </Card>
  );
}

export default TradeDetailsPage;
