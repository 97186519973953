import { AccountsContext } from '@/shared/context/AccountsProvider';
import { ErrorContext } from '@/shared/context/ErrorProvider';
import { matchesTraderId } from '@/shared/cryptoUtil';
import {
  displayDefaultTableCell,
  StyledHeaderTableCellWithLine,
  StyledTableCell,
} from '@/shared/orderTable/util';
import { useTheme } from '@emotion/react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Icon,
  Link,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader';
import ICONS from '../../../images/exchange_icons';
import arweaveLight from '../../../images/logos/arweave-light.png';
import { getArweaveData } from '../../apiServices';
import { insertEllipsis } from './insertEllipsis';
import { TraderIdAutocomplete } from './TraderIdAutocomplete';

const columns = [
  { id: 'id', label: 'Transaction ID', width: 260, align: 'left' },
  { id: 'exchange_name', label: 'Exchange', width: 50, align: 'left' },
  { id: 'trader_id', label: 'User', width: 60, align: 'left' },
  { id: 'epoch', label: 'Epoch', width: 30, align: 'left' },
  { id: 'merkle_root', label: 'Hash', width: 80, align: 'left' },
  { id: 'actions', label: 'Actions', width: 30, align: 'center' },
  // { id: 'usd_volume', label: 'Volume', width: 30, align: 'right' },
];

/**
 * Component that displays a table of tokenized trades from the blockchain
 * @param {boolean} isPreviewOnly - Whether to display a preview of the trades
 */
export default function ExplorerTradesTable({ isPreviewOnly }) {
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cursors, setCursors] = useState([]);
  const [traderIdFilter, setTraderIdFilter] = useState('');
  const { showAlert } = useContext(ErrorContext);

  const rowsPerPage = isPreviewOnly ? 10 : 25;

  const navigate = useNavigate();
  const theme = useTheme();
  const { accounts } = useContext(AccountsContext);

  const fetchTransactions = async (rows, cursor = null) => {
    setLoading(true);

    try {
      const result = await getArweaveData(rows, cursor);
      const resultTransactions = result.edges.map((edge, idx) => {
        return edge.node;
      });

      const nextPageCursor =
        result.edges.length > 0
          ? result.edges[result.edges.length - 1].cursor
          : null;

      const resultTransactionsWithTags = resultTransactions.map((tx) => {
        const tags = tx.tags.reduce((acc, tag) => {
          acc[tag.name] = tag.value;
          return acc;
        }, {});
        return {
          ...tx,
          ...tags,
        };
      });
      setTransactions(resultTransactionsWithTags);
      setCursors((prevCursors) => {
        if (!cursor) {
          return [nextPageCursor];
        }
        return [...prevCursors, nextPageCursor];
      });
    } catch (error) {
      showAlert({
        message: `Error fetching transactions: ${error.message}`,
        severity: 'error',
      });
    }
    setLoading(false);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      fetchTransactions(rowsPerPage, cursors[page]);
    } else if (newPage < page) {
      fetchTransactions(rowsPerPage, cursors[newPage - 1] || null);
    }
    setPage(newPage);
  };

  const handleTraderIdFilterChange = (event) => {
    setTraderIdFilter(event.target.value);
  };

  useEffect(() => {
    fetchTransactions(rowsPerPage, null);

    const transactioninterval = setInterval(() => {
      fetchTransactions(rowsPerPage, cursors[page]);
    }, 600000);

    return () => {
      clearInterval(transactioninterval);
    };
  }, []);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '95%',
        width: '100%',
      }}
    >
      {isPreviewOnly && (
        <CardHeader
          subheader={
            <Typography align='left' sx={{ mt: 1 }} variant='subtitle2'>
              Trades are securely stored on Arweave for permanent and
              transparent record-keeping.
            </Typography>
          }
          title={
            <Stack
              alignItems='flex-start'
              direction='row'
              spacing={2}
              style={{ flexShrink: 0 }}
            >
              <img
                alt='Arweave Logo Light'
                src={arweaveLight}
                style={{ height: '1.8rem' }}
              />
              <Typography align='left' variant='h4'>
                Tokenized Trades
              </Typography>
            </Stack>
          }
        />
      )}
      <CardContent
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          overflow: 'hidden', // Prevent content overflow
        }}
      >
        <Box sx={{ flexShrink: 0, mb: 2 }}>
          <TraderIdAutocomplete
            accounts={accounts}
            value={traderIdFilter}
            onChange={(event, newValue) => {
              setTraderIdFilter(newValue || '');
            }}
            onInputChange={(event, newInputValue) => {
              setTraderIdFilter(newInputValue);
            }}
          />
        </Box>

        {loading ? (
          <Box
            alignItems='center'
            display='flex'
            flex={1}
            justifyContent='center'
          >
            <ScaleLoader color={theme.palette.text.offWhite} />
          </Box>
        ) : (
          <TableContainer sx={{ flex: 1, overflow: 'auto' }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <StyledHeaderTableCellWithLine
                      align={column.align}
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        width: column.width || undefined,
                      }}
                    >
                      {column.label}
                    </StyledHeaderTableCellWithLine>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ overflow: 'auto' }}>
                {transactions
                  .filter(
                    (tx) =>
                      !traderIdFilter ||
                      matchesTraderId(traderIdFilter, tx.trader_id)
                  )
                  .map((tx) => {
                    return (
                      <TableRow hover key={tx.id}>
                        {columns.map((column) => {
                          const value = tx[column.id];
                          switch (column.id) {
                            case 'exchange_name':
                              return (
                                <StyledTableCell
                                  align={column.align}
                                  key={column.id}
                                >
                                  <Stack
                                    alignItems='center'
                                    direction='row'
                                    spacing={1}
                                  >
                                    <Icon
                                      sx={{
                                        alignItems: 'center',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        height: '1.4rem',
                                        justifyContent: 'center',
                                        mx: '-0.3rem',
                                        width: '1.4rem',
                                        '& svg': {
                                          maxHeight: '100%',
                                          maxWidth: '100%',
                                        },
                                      }}
                                    >
                                      <img
                                        alt='exchange icon'
                                        src={
                                          ICONS[value.toLowerCase()] ||
                                          ICONS.default
                                        }
                                      />
                                    </Icon>
                                    <Typography variant='body1'>
                                      {value.charAt(0).toUpperCase() +
                                        value.slice(1)}
                                    </Typography>
                                  </Stack>
                                </StyledTableCell>
                              );
                            case 'merkle_root':
                              return displayDefaultTableCell(
                                column,
                                insertEllipsis(value, 8, 6),
                                {},
                                StyledTableCell
                              );
                            case 'trader_id':
                              return displayDefaultTableCell(
                                column,
                                insertEllipsis(value),
                                {},
                                StyledTableCell
                              );
                            case 'id':
                              return (
                                <StyledTableCell
                                  align={column.align}
                                  key={column.id}
                                >
                                  <Link href={`//arweave.app/tx/${value}`}>
                                    {insertEllipsis(value, 8, 6)}
                                  </Link>
                                </StyledTableCell>
                              );
                            case 'actions':
                              return (
                                <StyledTableCell
                                  align={column.align}
                                  key={column.id}
                                >
                                  <Button
                                    size='small'
                                    variant='text'
                                    onClick={() =>
                                      navigate(`/explorer/trades/${tx.id}`)
                                    }
                                  >
                                    View details
                                  </Button>
                                </StyledTableCell>
                              );
                            default:
                              return displayDefaultTableCell(
                                column,
                                value,
                                {},
                                StyledTableCell
                              );
                          }
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Box sx={{ flexShrink: 0, mt: 2 }}>
          {isPreviewOnly ? (
            <Button
              sx={{
                border: 0,
                borderColor: theme.palette.text.offWhite,
                color: theme.palette.text.offWhite,
                height: '50px',
                width: '100%',
              }}
              variant='outlined'
              onClick={() => navigate('/explorer/trades')}
            >
              View all trades
            </Button>
          ) : (
            <TablePagination
              component='div'
              count={-1}
              page={page}
              rowsPerPage={isPreviewOnly ? rowsPerPage : 25}
              rowsPerPageOptions={[10]}
              onPageChange={handleChangePage}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
