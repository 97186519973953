import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box, Typography } from '@mui/material';
import usePlacementsData from './hooks/usePlacementsData';
import OrderDetailTable from './OrderDetailTable';

function PlacementsTable({
  orderId,
  isSimpleOrderView = false,
  isDetailOrderView = false,
}) {
  const [onlyActive, setOnlyActive] = useState(false);

  const {
    placementsDataLoading,
    placementsData,
    totalPlacements,
    currentPageNumber,
    setCurrentPageNumber,
    currentPageSize,
  } = usePlacementsData(orderId, onlyActive);

  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100% - 30px)',
      }}
    >
      <OrderDetailTable
        data={placementsData}
        dataLoading={placementsDataLoading}
        isDetailOrderView={isDetailOrderView}
        isSimpleOrderView={isSimpleOrderView}
        paginationProps={{
          totalRows: totalPlacements,
          currentPageNumber,
          setCurrentPageNumber,
          currentPageSize,
        }}
        title='Placements'
      />
      {isDetailOrderView && (placementsData?.length > 0 || onlyActive) && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={onlyActive}
                onChange={(e) => setOnlyActive(e.target.checked)}
              />
            }
            label={
              <Typography variant='small2'>Active Placements Only</Typography>
            }
          />
        </Box>
      )}
    </Box>
  );
}

export default PlacementsTable;
