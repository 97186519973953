import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc';
import React, { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { FormControl, Stack, TableContainer, TextField } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useOrderForm } from '@/shared/context/OrderFormProvider';
import { OrderEntryType } from '@/pages/dashboard/orderEntry/util';

import {
  ApiError,
  createOrderTemplate,
  deleteOrderTemplates,
  openInNewTab,
  submitOrder,
  submitChainedOrder,
  getMinQty,
} from '../../../../apiServices';
import { StyledTableCell } from '../../../../shared/orderTable/util';
import { removeFalsyAndEmptyKeys, titleCase } from '../../../../util';
import { useUserMetadata } from '../../../../shared/context/UserMetadataProvider';
import { OPEN_NEW_TAB_ON_SUBMIT } from '../../../../constants';

dayjs.extend(dayjsPluginUTC);

const parseTargetTime = (selectedDuration, targetTime) => {
  const time = targetTime;
  const targetTimeCond = dayjs
    .utc(time)
    .subtract(selectedDuration / 2, 'seconds')
    .format('YYYY-MM-DDTHH:mm');
  return `TIME>dt${targetTimeCond}`;
};

export const useSubmitForm = ({
  setHasError,
  showAlert,
  optionSubmit = false,
  isChainedOrder = false,
}) => {
  const { user } = useUserMetadata();
  const [openModal, setOpenModal] = useState(false);
  const [submitModalMessage, setSubmitModalMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const {
    selectedAccounts,
    baseQty,
    selectedDuration,
    limitPrice,
    notes,
    orderCondition,
    selectedPair,
    quoteQty,
    selectedSide,
    stopPrice,
    selectedStrategy,
    selectedStrategyParams,
    updatePairLeverage,
    povLimit,
    preTradeEstimationData,
    povTarget,
    convertedQty,
    trajectory,
    targetTime,
    isOOLEnabled,
    setIsOOLEnabled,
    alphaTilt,
    passiveness,
    discretion,
    durationStartTime,
    initialLoadValue,
    maxOtcPercentage,
    posSide,
    orderSlices,
    isReverseLimitPrice,
    maxClipSize,
    setMaxClipSize,
    orderEntryType,
    urgency,
  } = useOrderForm();

  const openNewTabOnSubmit = user.preferences
    ? user.preferences[OPEN_NEW_TAB_ON_SUBMIT]
    : false;

  const { accounts, strategies, trajectories, superStrategies } =
    initialLoadValue;

  const getStrategyName = () => {
    if (
      selectedStrategy &&
      superStrategies[selectedStrategy] &&
      superStrategies[selectedStrategy].name
    ) {
      return superStrategies[selectedStrategy].name;
    }
    if (
      selectedStrategy &&
      strategies[selectedStrategy] &&
      strategies[selectedStrategy].name
    ) {
      return strategies[selectedStrategy].name;
    }
    return null;
  };

  const getTrajectoryName = () => {
    if (
      trajectory &&
      trajectories[trajectory] &&
      trajectories[trajectory].name
    ) {
      return trajectories[trajectory].name;
    }
    return '';
  };

  const parseFormData = (confirmation = false) => {
    const transformPov = (pov) => {
      return pov ? parseFloat(pov) / 100 : null;
    };

    const getPair = () => {
      if (selectedPair) {
        if (optionSubmit) {
          return selectedPair.name;
        }
        if (!confirmation) {
          return selectedPair.id;
        }
        return selectedPair.label;
      }
      return selectedPair;
    };

    let super_strategy = null;
    if (
      selectedStrategy &&
      superStrategies &&
      superStrategies[selectedStrategy] &&
      superStrategies[selectedStrategy].is_super_strategy
    ) {
      super_strategy = selectedStrategy;
    }

    const duration = Math.max(selectedDuration, 60);

    const autoOrderMetadata =
      orderEntryType === OrderEntryType.AUTO.key ? { urgency } : {};

    return {
      alpha_tilt: alphaTilt,
      accounts: selectedAccounts,
      base_asset_qty: baseQty,
      duration,
      engine_passiveness: passiveness,
      limit_price: limitPrice,
      is_reverse_limit_price: isReverseLimitPrice,
      notes,
      order_condition:
        superStrategies &&
        superStrategies[super_strategy] &&
        superStrategies[super_strategy].name === 'Target Time'
          ? parseTargetTime(selectedDuration, targetTime)
          : orderCondition,
      pair: getPair(),
      quote_asset_qty: quoteQty,
      schedule_discretion: discretion,
      side: selectedSide,
      stop_price: stopPrice,
      strategy: trajectory,
      strategy_params: {
        ...selectedStrategyParams,
        ool_pause: isOOLEnabled,
        max_clip_size: maxClipSize,
      },
      updated_leverage: updatePairLeverage,
      pov_limit: transformPov(povLimit),
      pov_target: transformPov(povTarget),
      pre_trade_data: preTradeEstimationData,
      super_strategy,
      start_datetime: durationStartTime
        ? durationStartTime.setZone('utc').toISO()
        : null,
      max_otc: maxOtcPercentage / 100,
      pos_side: posSide,
      order_slices: orderSlices,
      auto_order_metadata: autoOrderMetadata,
    };
  };

  const getOrderPath = (order) => {
    let url = null;
    if (order.parent_order) {
      url = `/multi_order/${order.parent_order}`;
    } else if (order.is_chained) {
      url = `/chained_orders/${order.chained_order}`;
    } else if (order.is_simple) {
      url = `/simple_order/${order.id}`;
    } else {
      url = `/order/${order.id}`;
    }

    return url;
  };

  const getFormData = (confirmation = false) => {
    const formData = parseFormData(confirmation);
    const vettedFormData = confirmation
      ? formData
      : {
          ...removeFalsyAndEmptyKeys(formData),
          alpha_tilt: alphaTilt,
          engine_passiveness: passiveness,
          schedule_discretion: discretion,
        };
    return vettedFormData;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setHasError(false);
    setOpenModal(false);
    setSubmitModalMessage('');
    setIsSubmitted(true);

    const orderFields = getFormData();

    try {
      let response;

      if (isChainedOrder) {
        response = await submitChainedOrder(orderFields);
      } else {
        response = await submitOrder(orderFields);
      }

      if (response.id) {
        setIsSubmitted(false);
        if (openNewTabOnSubmit) {
          openInNewTab(getOrderPath(response));
        }
      } else {
        showAlert({
          severity: 'error',
          message: response,
        });
      }
    } catch (e) {
      if (e instanceof ApiError) {
        showAlert({
          severity: 'error',
          message: e.message,
        });
      } else {
        throw e;
      }
      setIsSubmitted(false);
    }
  };

  const submitCheck = async (event, relevantExchangePairs) => {
    event.preventDefault();

    if (relevantExchangePairs.length > 0) {
      const baseAssetQty = Number(baseQty || convertedQty);
      const lowestMaxBaseQty = Math.min(
        ...relevantExchangePairs.map((pair) =>
          Number(pair.max_base_asset_market)
        )
      );

      if (baseAssetQty && baseAssetQty > 100 * lowestMaxBaseQty) {
        setSubmitModalMessage(
          `This order may not complete due to trade limits (market order maximum quantity limit: ${lowestMaxBaseQty})`
        );
        setOpenModal(true);
        return;
      }
    }

    const quoteAssetQty = Number(quoteQty || convertedQty);
    let quoteMinQty = null;

    const accountExchanges = selectedAccounts.map(
      (account) => accounts[account].exchangeName
    );

    try {
      const minResult = await getMinQty(selectedPair.id, accountExchanges);
      quoteMinQty = Number(minResult.min_qty);
    } catch (e) {
      showAlert({ severity: 'error', message: e.message });
    }

    if (
      quoteMinQty &&
      (quoteAssetQty || quoteAssetQty === 0) &&
      quoteAssetQty < 2 * quoteMinQty
    ) {
      setSubmitModalMessage(
        `This order may not complete due to trade limits (min limit:
         ${quoteMinQty} notional)`
      );
      setOpenModal(true);
      return;
    }

    const { pov } = preTradeEstimationData;
    if (pov && Number(pov) > 2.5) {
      setSubmitModalMessage(
        'This order may have significant market impact ' +
          `due to an expected high participation rate of ${Number(pov).toFixed(2)}%`
      );
      setOpenModal(true);
      return;
    }

    setSubmitModalMessage('');
    setOpenModal(true);
  };

  function ConfirmationModalProps(isBuySide) {
    return {
      convertedQty,
      data: getFormData(true),
      handleConfirm: handleSubmit,
      isBuy: isBuySide,
      modalText: submitModalMessage,
      open: openModal,
      optionSubmit,
      setOpen: setOpenModal,
      strategies,
      superStrategies,
      limitPrice,
      trajectories,
    };
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
  };

  const closeButtonStyle = {
    position: 'absolute',
    right: 8,
    top: 8,
  };

  function OrderTemplateModal({
    type,
    open,
    setOpen,
    orderTemplates,
    setSelectedAccounts,
    setSelectedSide,
    setSelectedPair,
    handleBaseChange,
    handleQuoteChange,

    setPovLimit,
    setPovTarget,
    setOrderCondition,
    setAlphaTilt,
    setDiscretion,
    setPassiveness,
    setSelectedStrategy,
    setTrajectory,
    setSelectedStrategyParams,
    setSelectedDuration,
    setUpdatePairLeverage,

    setTargetTime,
    setStopPrice,
    setLimitPrice,
    setNotes,
    setLoading,
  }) {
    const [selectedTemplate, setSelectedTemplate] = useState(orderTemplates[0]);
    const [orderSaveName, setOrderSaveName] = useState('');
    const [orderFormState, setOrderFormState] = useState([]);
    const { limitPriceQuickSetting, setLimitPriceQuickSetting } =
      useOrderForm();
    const getValues = (setter = false) => {
      return {
        accounts: setter ? setSelectedAccounts : selectedAccounts,
        pair: setter ? setSelectedPair : selectedPair,
        side: setter ? setSelectedSide : selectedSide,
        base_asset_qty: setter ? handleBaseChange : baseQty,
        quote_asset_qty: setter ? handleQuoteChange : quoteQty,
        super_strategy: setter ? setSelectedStrategy : selectedStrategy,
        strategy: setter ? setTrajectory : trajectory,
        // eslint-disable-next-line no-nested-ternary
        duration: setter
          ? setSelectedDuration
          : !povTarget
            ? selectedDuration
            : undefined,
        engine_passiveness: setter ? setPassiveness : passiveness,
        alpha_tilt: setter ? setAlphaTilt : alphaTilt,
        schedule_discretion: setter ? setDiscretion : discretion,
        strategy_params: setter
          ? setSelectedStrategyParams
          : {
              ...selectedStrategyParams,
              ool_pause: isOOLEnabled,
            },
        order_condition: setter ? setOrderCondition : orderCondition,
        notes: setter ? setNotes : notes,
        // eslint-disable-next-line no-nested-ternary
        // limit_price:  selectedLimitPriceQuickSetting ?
        //   (setter ? setSelectedLimitPriceQuickSetting : selectedLimitPriceQuickSetting ) :
        //   (setter ? setLimitPrice : limitPrice),
        limit_price: setter ? setLimitPrice : limitPrice,
        limit_price_options: setter
          ? setLimitPriceQuickSetting
          : limitPriceQuickSetting,

        updated_leverage: setter ? setUpdatePairLeverage : updatePairLeverage,
        pov_limit: setter ? setPovLimit : povLimit,
        pov_target: setter ? setPovTarget : povTarget,
        // Target time should only show for Target Time super strat
        target_time: setter
          ? setTargetTime
          : getStrategyName() === 'Target Time' && targetTime.toISOString(),
        stop_price: setter ? setStopPrice : stopPrice,
        max_clip_size: setter ? setMaxClipSize : maxClipSize,
      };
    };

    const getDisplayNames = {
      accounts: 'Accounts',
      pair: 'Pair',
      side: 'Side',
      base_asset_qty: 'Base Quantity',
      quote_asset_qty: 'Quote Quantity',
      super_strategy: 'Super Strategy',
      strategy: 'Trajectory',
      duration: 'Duration',
      engine_passiveness: 'Passiveness',
      alpha_tilt: 'Alpha Tilt',
      schedule_discretion: 'Discretion',
      strategy_params: 'Strategy Params',
      order_condition: 'Order Condition',
      notes: 'Notes',
      limit_price: 'Limit Price',
      updated_leverage: 'Updated Leverage',
      pov_limit: 'POV Limit',
      pov_target: 'POV Target',
      target_time: 'Target Time',
      stop_price: 'Stop Price',
      max_clip_size: 'Max Clip Size',
    };

    const getDisplayValues = () => {
      const templateValueMapping = {};

      Object.entries(selectedTemplate.values).forEach(([k, v]) => {
        const displayKey = k;
        switch (k) {
          case 'accounts':
            templateValueMapping[displayKey] = v
              ? v.map((names, index) => {
                  if (index === v.length - 1) {
                    return `${names}`;
                  }
                  return `${names} | `;
                })
              : '';
            break;
          case 'pair':
            templateValueMapping[displayKey] = v ? v.label : '';
            break;
          case 'side':
            templateValueMapping[displayKey] = titleCase(v);
            break;
          case 'strategy_params':
            templateValueMapping[displayKey] =
              Object.keys(v).length !== 0
                ? Object.keys(v).map((key) => {
                    if (!v[key]) {
                      return null;
                    }
                    return (
                      <Typography key={key}>
                        <li>{titleCase(key)}</li>
                      </Typography>
                    );
                  })
                : '';
            break;
          case 'notes':
          case 'order_condition':
            templateValueMapping[displayKey] = (
              <Typography sx={{ wordWrap: 'breakWord' }}>{v}</Typography>
            );
            break;
          case 'strategy':
            templateValueMapping[displayKey] = getTrajectoryName();
            break;
          case 'super_strategy':
            templateValueMapping[displayKey] = getStrategyName();
            break;
          case 'limit_price_options':
            templateValueMapping.limit_price = v;
            break;
          default:
            templateValueMapping[displayKey] = v;
        }
      });

      return Object.keys(getDisplayNames).map((key) => {
        if (
          selectedTemplate &&
          Object.keys(selectedTemplate.values).includes(key)
        ) {
          return [getDisplayNames[key], templateValueMapping[key]];
        }
        return [getDisplayNames[key], ''];
      });
    };

    useEffect(() => {
      if (selectedTemplate) {
        setOrderFormState(getDisplayValues());
      }
    }, [selectedTemplate]);

    const loadOrderTemplate = async (template) => {
      if (Object.keys(template.values).length < 1) {
        return;
      }
      setOpen(false);
      setLoading(true);

      const getValuesWithSetters = getValues(true);

      const getValuesKeys = Object.keys(getValues());
      if (Object.keys(template.values).includes('side')) {
        getValuesWithSetters.side(template.values.side);
      }

      if (Object.keys(template.values).includes('accounts')) {
        getValuesWithSetters.accounts(template.values.accounts);
      }

      if (Object.keys(template.values).includes('pair')) {
        getValuesWithSetters.pair(template.values.pair);
      }

      await new Promise((r) => {
        setTimeout(r, 2000);
      })
        .then(() => {
          Object.keys(template.values).forEach((templateKey) => {
            const foundValue = getValuesKeys.find((ele) => ele === templateKey);
            if (foundValue) {
              if (foundValue === 'limit_price') {
                if (
                  Object.keys(template.values).includes('limit_price_options')
                ) {
                  getValuesWithSetters.limit_price_options(
                    template.values.limit_price_options
                  );
                } else {
                  getValuesWithSetters[foundValue](
                    template.values[templateKey]
                  );
                }

                if (
                  Object.keys(template.values.strategy_params) > 0 &&
                  template.values.strategy_params.ool_pause
                ) {
                  setIsOOLEnabled(true);
                }
                if (
                  Object.keys(template.values.strategy_params) > 0 &&
                  template.values.strategy_params.max_clip_size
                ) {
                  setMaxClipSize(template.values.max_clip_size);
                }
              } else {
                getValuesWithSetters[foundValue](template.values[templateKey]);
              }
            }
          });
        })
        .then(() => {
          if (Object.keys(template.values).includes('quote_asset_qty')) {
            getValuesWithSetters.quote_asset_qty(
              Number(template.values.quote_asset_qty)
            );
          }

          if (Object.keys(template.values).includes('base_asset_qty')) {
            getValuesWithSetters.base_asset_qty(
              Number(template.values.base_asset_qty)
            );
          }
        })
        .then(() => {
          setLoading(false);
        });
    };

    const saveOrderTemplate = (templateName) => {
      if (templateName === '') {
        return;
      }
      const newValues = removeFalsyAndEmptyKeys(getValues());

      try {
        const body = {
          name: templateName,
          values: newValues,
        };
        createOrderTemplate(body);
      } catch (err) {
        showAlert({
          severity: 'error',
          message: `Unable to create template: ${err.message}`,
        });
      }
      setOpen(false);
    };

    const deleteOrderTemplate = (template) => {
      deleteOrderTemplates([template.id]);
      setOpen(false);
    };

    return (
      <div>
        <Modal
          closeAfterTransition
          aria-describedby='transition-modal-description'
          aria-labelledby='transition-modal-title'
          open={open}
          PaperProps={{
            sx: {
              minHeight: '80%',
              maxHeight: '80%',
            },
          }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          slots={{ backdrop: Backdrop }}
          onClose={() => setOpen(false)}
        >
          <Fade in={open}>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              sx={modalStyle}
            >
              <Typography
                gutterBottom
                style={{ marginLeft: '12px', marginBottom: '12px' }}
                variant='h2'
              >
                Order Templates
              </Typography>
              <IconButton
                aria-label='close'
                sx={closeButtonStyle}
                onClick={() => setOpen(false)}
              >
                <CloseIcon />
              </IconButton>
              <div>
                {type === 'manage' ? (
                  <>
                    <Stack direction='row' spacing={2}>
                      <FormControl sx={{ width: '50%' }}>
                        <InputLabel id='template-label'>Templates</InputLabel>
                        <Select
                          label='Templates'
                          labelId='template-label'
                          size='small'
                          sx={{ width: '100%' }}
                          value={selectedTemplate}
                          onChange={(e) => setSelectedTemplate(e.target.value)}
                        >
                          {orderTemplates.map((template) => (
                            <MenuItem key={template.id} value={template}>
                              {template.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Button
                        color='secondary'
                        size='small'
                        sx={{ width: '25%' }}
                        variant='contained'
                        onClick={async (e) => {
                          e.preventDefault();
                          await loadOrderTemplate(selectedTemplate);
                        }}
                      >
                        Load
                      </Button>
                      <Button
                        color='secondary'
                        size='small'
                        sx={{ width: '25%' }}
                        variant='contained'
                        onClick={() => deleteOrderTemplate(selectedTemplate)}
                      >
                        Delete
                      </Button>
                    </Stack>
                    <TableContainer sx={{ height: '70vh' }}>
                      <Table
                        aria-label='a dense table'
                        size='small'
                        sx={{ minWidth: 450, overflow: 'auto' }}
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell />
                            <StyledTableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {orderFormState.map((k) => (
                            <TableRow key={k[0]}>
                              <StyledTableCell
                                sx={{
                                  borderRight: '1px solid rgb(81 81 81)',
                                  width: '160px',
                                }}
                              >
                                {k[0]}
                              </StyledTableCell>
                              <StyledTableCell sx={{ maxWidth: '300px' }}>
                                {k[1]}
                              </StyledTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                ) : (
                  <Stack direction='row' spacing={2}>
                    <TextField
                      label='Name'
                      size='small'
                      sx={{ width: '75%' }}
                      value={orderSaveName}
                      onChange={(e) => {
                        setOrderSaveName(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          saveOrderTemplate(e.target.value);
                        }
                      }}
                    />
                    <Button
                      fullWidth
                      color='secondary'
                      label='Name'
                      size='small'
                      sx={{ width: '25%' }}
                      variant='contained'
                      onClick={() => saveOrderTemplate(orderSaveName)}
                    >
                      Save
                    </Button>
                  </Stack>
                )}
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }

  return {
    ConfirmationModalProps,
    submitCheck,
    isSubmitted,
    OrderTemplateModal,
    getFormData,
  };
};
