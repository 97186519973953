import { theme } from '@/theme/theme';
import {
  Box,
  Stack,
  Paper,
  ThemeProvider,
  styled,
  InputLabel,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import React from 'react';
import { smartRound, msAndKs, titleCase } from '@/util';
import { HeaderTypography } from '@/shared/components/MuiComponents';
import { TreadTooltip } from '@/shared/components/LabelTooltip';
import { fillRoleColor } from './charts/util';

function BenchmarkCard({ children }) {
  return (
    <Paper
      elevation={1}
      sx={{
        px: '12px',
        py: '8px',
        height: '100%',
        boxSizing: 'border-box',
      }}
    >
      {children}
    </Paper>
  );
}

const moveNegativeToFront = (value) => {
  if (value < 0) {
    return `-$${Math.abs(value)}`;
  }
  return `$${value}`;
};

function ArrivalCost({ arrival_cost, arrival_bps_notional, arrival_price }) {
  return (
    <Stack direction='column' spacing={1}>
      <InputLabel
        sx={{
          color: theme.palette.text.subtitle,
        }}
      >
        <TreadTooltip
          labelTextVariant='small2'
          placement='left'
          variant='slippage'
        />
      </InputLabel>

      {arrival_cost ? (
        <Stack alignItems='baseline' direction='row' spacing={0}>
          <Typography
            color={arrival_cost > 0 ? 'error.main' : 'success.main'}
            variant='subtitle1'
          >
            {smartRound(arrival_cost, 2)}
          </Typography>
          <Typography
            color={arrival_cost > 0 ? 'error.main' : 'success.main'}
            variant='small1'
          >
            {'bps\u00A0'}
          </Typography>
          <Typography variant='small1'>{`≈ ${moveNegativeToFront(smartRound(arrival_bps_notional, 2))}`}</Typography>
        </Stack>
      ) : (
        <Typography>-</Typography>
      )}

      <HeaderTypography>
        Benchmark: ${smartRound(arrival_price, 2)}
      </HeaderTypography>
    </Stack>
  );
}

function VWAPCost({ vwap_cost, vwap }) {
  return (
    <Stack direction='column' spacing={1}>
      <InputLabel
        sx={{
          color: theme.palette.text.subtitle,
        }}
      >
        <TreadTooltip
          labelTextVariant='small2'
          placement='left'
          variant='vwap_slippage'
        />
      </InputLabel>
      {vwap_cost ? (
        <Stack alignItems='baseline' direction='row' spacing={0}>
          <Typography
            color={vwap_cost > 0 ? 'error.main' : 'success.main'}
            variant='subtitle1'
          >
            {smartRound(vwap_cost, 2)}
          </Typography>
          <Typography
            color={vwap_cost > 0 ? 'error.main' : 'success.main'}
            variant='small1'
          >
            bps
          </Typography>
        </Stack>
      ) : (
        <Typography>-</Typography>
      )}
      <HeaderTypography>Benchmark: ${smartRound(vwap, 2)}</HeaderTypography>
    </Stack>
  );
}

function ExchangeFee({ fee_cost, fee_asset, fee_notional, fillRoleBreakdown }) {
  const renderFee = () => {
    if (fee_asset === 'USD' || !fee_asset) {
      return `$${smartRound(fee_notional)}`;
    }

    return `${smartRound(fee_notional)} ${fee_asset}`;
  };

  const formatRole = (role) => {
    if (['MAKE', 'TAKE'].includes(role)) {
      return titleCase(`${role}r`);
    }

    return titleCase(role);
  };

  const renderFillRoleBreakdown = () => {
    if (!fillRoleBreakdown) {
      return <div />;
    }

    return (
      <Stack direction='row'>
        {Object.entries(fillRoleBreakdown).map(([role, value], i) => {
          return (
            <Stack direction='row' key={role} spacing={1}>
              {i !== 0 && <Typography variant='small2'>/</Typography>}
              <Typography variant='small2'>
                {Number(value).toFixed(0)}%
              </Typography>
              <Typography
                color={fillRoleColor({ theme, role })}
                variant='small2'
              >
                {formatRole(role)}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    );
  };

  const renderFeeCost = () => {
    if (!fee_cost) {
      return 'Not Available';
    }

    return `${Number(fee_cost).toFixed(1)}`;
  };
  return (
    <Stack direction='column' spacing={1}>
      <InputLabel
        sx={{
          color: theme.palette.text.subtitle,
        }}
      >
        <TreadTooltip
          labelTextVariant='small2'
          placement='left'
          variant='exchange_fee'
        />
      </InputLabel>
      {fee_cost ? (
        <Stack alignItems='baseline' direction='row' spacing={0}>
          <Typography variant='subtitle1'>{renderFee()}</Typography>{' '}
          <Typography variant='small1'>{`\u00A0≈ ${renderFeeCost()}bps`}</Typography>
        </Stack>
      ) : (
        <Typography>-</Typography>
      )}
      {renderFillRoleBreakdown()}
    </Stack>
  );
}

function ParticipationRate({ pov, interval_volume }) {
  return (
    <Stack direction='column' spacing={1}>
      <InputLabel
        sx={{
          color: theme.palette.text.subtitle,
        }}
      >
        <TreadTooltip
          labelTextVariant='small2'
          placement='left'
          variant='benchmark_participation_rate'
        />
      </InputLabel>
      {pov ? (
        <Typography variant='subtitle1'>{smartRound(pov, 3)}%</Typography>
      ) : (
        <Typography>-</Typography>
      )}
      <HeaderTypography>
        {interval_volume
          ? `Volume: ${msAndKs(Number(interval_volume))}`
          : 'Volume: -'}
      </HeaderTypography>
    </Stack>
  );
}

function OrderBenchmarks({
  benchmarkData,
  fillRoleBreakdown,
  isSimple = false,
}) {
  const {
    arrival_price,
    arrival_cost,
    arrival_bps_notional,
    vwap,
    vwap_cost,
    fee_notional,
    fee_asset,
    fee_cost,
    interval_volume,
    pov,
  } = benchmarkData;

  const cardSize = isSimple ? 6 : 3;
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Grid container direction='row' spacing={1} sx={{ height: '100%' }}>
          <Grid xs={cardSize}>
            <BenchmarkCard>
              <ArrivalCost
                arrival_bps_notional={arrival_bps_notional}
                arrival_cost={arrival_cost}
                arrival_price={arrival_price}
              />
            </BenchmarkCard>
          </Grid>
          {!isSimple && (
            <Grid xs={cardSize}>
              <BenchmarkCard>
                <VWAPCost vwap={vwap} vwap_cost={vwap_cost} />
              </BenchmarkCard>
            </Grid>
          )}
          <Grid xs={cardSize}>
            <BenchmarkCard>
              <ExchangeFee
                fee_asset={fee_asset}
                fee_cost={fee_cost}
                fee_notional={fee_notional}
                fillRoleBreakdown={fillRoleBreakdown}
              />
            </BenchmarkCard>
          </Grid>
          {!isSimple && (
            <Grid xs={cardSize}>
              <BenchmarkCard>
                <ParticipationRate
                  interval_volume={interval_volume}
                  pov={pov}
                />
              </BenchmarkCard>
            </Grid>
          )}
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export { OrderBenchmarks };
