import React from 'react';
import { Icon, Stack, Typography } from '@mui/material';
import ICONS from '@images/exchange_icons';

function OrderAccounts({ accounts, selectedAccounts }) {
  return (
    <Stack direction='row' spacing={2}>
      {selectedAccounts.map((accName, index) => {
        const account = accounts[accName];
        const iconUrl = ICONS[account?.exchangeName?.toLowerCase()];

        const isLast = index === selectedAccounts.length - 1;
        return (
          <Stack alignItems='center' direction='row' key={accName} spacing={2}>
            {iconUrl && (
              <Icon sx={{ borderRadius: '50%' }}>
                <img alt='exchange icon' src={iconUrl} />
              </Icon>
            )}
            <Typography>{isLast ? `${accName}` : `${accName},`}</Typography>
          </Stack>
        );
      })}
    </Stack>
  );
}

export default OrderAccounts;
