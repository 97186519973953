import { styled, TableCell } from '@mui/material';

export const StyledSummaryTableCell = styled(TableCell)(({ theme }) => {
  return {
    [`&.${StyledSummaryTableCell}-head`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    fontFamily: ['monospace', 'IBM Plex Mono'],
    fontSize: theme.typography.body1.fontSize,
    borderBottom: 'none',
    padding: '2px 0px 0px 0px',
  };
});

export const StyledHeaderTableCell = styled(TableCell)(({ theme }) => {
  return {
    [`&.${StyledHeaderTableCell}-head`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    fontSize: theme.typography.body1.fontSize,
    borderBottom: 'none',
    padding: '16px 0px 0px 0px',
  };
});
