import {
  StyledSmallTableCell,
  StyledTableCell,
} from '@/shared/orderTable/util';
import { Stack, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getUnderlying, numberWithSpaces, smartRound } from '@/util';
import getBaseTokenIcon from '../../../../images/tokens';
import AssetsTableCell from '../AssetsTableCell';

const assetTypeColumns = [
  { id: 'symbol', label: 'Name', minWidth: 70, align: 'left' },
  {
    id: 'notional',
    label: 'Notional',
    minWidth: 120,
    align: 'right',
    number: true,
  },
  {
    id: 'amount',
    label: 'Quantity',
    minWidth: 120,
    align: 'right',
    number: true,
  },
  {
    id: 'unrealized_profit',
    label: 'ROI($)',
    minWidth: 30,
    align: 'right',
    number: true,
  },
  {
    id: 'unrealized_profit_percentage',
    label: 'ROI(%)',
    minWidth: 30,
    align: 'right',
    number: true,
  },
  {
    id: 'allocation',
    label: 'Allocation',
    minWidth: 150,
    align: 'right',
    number: true,
  },
];

const calculatePnL = (row) => {
  if (row.asset_type === 'token') {
    return 0;
  }

  // % of unrealized PnL over original notional
  return 100 * (row.unrealized_profit / Math.abs(row.notional));
};

export function AccountAssetTable({
  marketTypeAssets,
  absMarketTypeTotalValue,
}) {
  const filterLowNotional = (assets) =>
    assets.filter((asset) => Math.abs(asset.notional) >= 0.01);
  return (
    <TableContainer sx={{ maxHeight: '400px' }}>
      <Table stickyHeader aria-label='sticky table'>
        <TableHead>
          <TableRow>
            {assetTypeColumns.map((column) => (
              <StyledSmallTableCell
                align={column.align}
                key={column.id}
                style={{
                  minWidth: column.minWidth,
                  width: column.width || undefined,
                }}
              >
                {column.label}
              </StyledSmallTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filterLowNotional(marketTypeAssets).map((row) => {
            const getPairIcon = row.symbol
              ? getBaseTokenIcon(getUnderlying(row.symbol))
              : null;
            return (
              <TableRow
                key={row.symbol + row.notional}
                style={{ width: '100%' }}
              >
                {assetTypeColumns.map((column) => {
                  let value = row[column.id];
                  switch (column.id) {
                    case 'unrealized_profit_percentage':
                      value = calculatePnL(row);
                      break;
                    case 'symbol':
                      return (
                        <StyledTableCell
                          key={column.id}
                          row={row}
                          style={{
                            minWidth: column.minWidth,
                            width: column.width || '20%',
                          }}
                          value={value}
                        >
                          <Stack
                            direction='row'
                            spacing={1}
                            sx={{
                              alignItems: 'center',
                            }}
                          >
                            {getPairIcon && (
                              <img
                                alt='Token Icon'
                                src={getPairIcon}
                                style={{ height: '30px', width: '30px' }}
                              />
                            )}
                            <Typography variant='body1'>{value}</Typography>
                          </Stack>
                        </StyledTableCell>
                      );
                    case 'allocation':
                      return (
                        <StyledTableCell
                          align={column.align}
                          key={column.id}
                          row={row}
                          style={{
                            minWidth: column.minWidth,
                            width: column.width || '20%',
                          }}
                          value={value}
                        >
                          {`${((Math.abs(row.notional) / absMarketTypeTotalValue) * 100).toFixed(2)}%`}
                        </StyledTableCell>
                      );
                    case 'amount': {
                      const precision =
                        row?.symbol && row.symbol.includes('USD') ? 2 : 4;
                      return (
                        <StyledTableCell
                          align={column.align}
                          key={column.id}
                          row={row}
                          style={{
                            minWidth: column.minWidth,
                            width: column.width || '20%',
                          }}
                          value={value}
                        >
                          <Typography variant='body1'>
                            {`${numberWithSpaces(smartRound(value, precision))} ${getUnderlying(row?.symbol)}`}
                          </Typography>{' '}
                        </StyledTableCell>
                      );
                    }
                    default:
                      break;
                  }
                  return (
                    <AssetsTableCell
                      column={column}
                      key={column.id}
                      row={row}
                      value={value}
                    />
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
