/* eslint-disable no-param-reassign */
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useAtom } from 'jotai';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { fetchOrderEntryFormData } from '@/apiServices';
import ChipStatusFilter from '@/shared/orderTable/ChipStatusFilter';
import { SharedOrderTable } from '@/shared/orderTable/SharedOrderTable';
import { useUserMetadata } from '@/shared/context/UserMetadataProvider';
import { ErrorContext } from '@/shared/context/ErrorProvider';
import useQueryParams from '@/shared/hooks/useQueryParams';
import { Loader } from '@/shared/Loader';
import DataComponent from '@/shared/DataComponent';
import { OrderSearch } from './OrderSearch';
import * as FormAtoms from '../dashboard/orderEntry/hooks/useFormReducer';

function OrderViewPage() {
  const [searchHandler, setSearchHandler] = useState(() => {});
  const [queryParams, setQueryParam] = useQueryParams();
  const [orders, setOrders] = useState([]);
  const [statusHighlight, setStatusHighlight] = useState(
    queryParams.status ? [queryParams.status] : []
  );
  const [typeFilter, setTypeFilter] = useState(
    queryParams.type ? [queryParams.type] : ['SINGLE']
  );
  const [loading, setLoading] = useState(true);
  const [sharedTablePageNumber, setSharedTablePageNumber] = useState(0);
  const { setHasError, setErrorContent } = useContext(ErrorContext);

  const [totalPages, setTotalPages] = useState(null);

  const [, setInitialLoadValue] = useAtom(FormAtoms.initialLoadValueAtom);

  const { user, isDev } = useUserMetadata();

  const getInitialData = async () => {
    let data;
    try {
      data = await fetchOrderEntryFormData();
    } catch (e) {
      setHasError(true);
      setErrorContent({
        severity: 'error',
        message: 'Failed to fetch initial data',
      });
      return;
    }

    setLoading(false);

    const pairs = data.pairs.map((p) => {
      return {
        base: p.base,
        exchanges: p.exchanges,
        id: p.name,
        is_contract: p.is_contract,
        is_inverse: p.is_inverse,
        label: p.name,
        market_type: p.market_type,
        quote: p.quote,
      };
    });

    const accounts = {};
    data.accounts.forEach((acc) => {
      const scopedAccName =
        acc.user === data.user_id ? acc.name : `${acc.username}/${acc.name}`;
      const displayName = `${acc.exchange} - ${scopedAccName}`;
      accounts[scopedAccName] = {
        displayName,
        id: acc.id,
        name: scopedAccName,
        exchangeName: acc.exchange,
      };
    });

    const indexedStrategies = data.strategies.reduce((obj, item) => {
      obj[item.id] = item;
      return obj;
    }, {});

    const indexedSuperStrategies = data.super_strategies.reduce((obj, item) => {
      obj[item.id] = item;
      return obj;
    }, {});

    setInitialLoadValue({
      tokenPairs: pairs,
      accounts,
      exchanges: data.exchanges,
      strategies: indexedSuperStrategies,
      trajectories: indexedStrategies,
      superStrategies: indexedSuperStrategies,
      strategyParams: data.strategy_params,
      orderTemplates: data.order_templates,
    });
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const handlePageCallback = (page) => {
    setSharedTablePageNumber(page);
  };

  const onClickTypeChipCallback = () => {
    setSharedTablePageNumber(0);
  };

  const setStatusHighlightCallback = useCallback((status) => {
    setQueryParam('status', status);
    setStatusHighlight(status);
  });

  const setTypeFilterCallback = useCallback((type) => {
    setQueryParam('type', type);
    setTypeFilter(type);
  });

  return (
    <Stack spacing={1} sx={{ height: '100%' }}>
      <Card sx={{ margin: 0, height: '80px' }}>
        <CardContent sx={{ overflowX: 'auto', margin: 0 }}>
          <OrderSearch
            setLoading={setLoading}
            setOrders={setOrders}
            setSearchHandler={setSearchHandler}
            setSharedTablePageNumber={setSharedTablePageNumber}
            setTotalPages={setTotalPages}
            sharedTablePageNumber={sharedTablePageNumber}
            statusHighlight={statusHighlight}
            typeFilter={typeFilter}
          />
        </CardContent>
      </Card>
      <Card style={{ height: 'calc(100% - 80px)' }}>
        <CardContent>
          <ChipStatusFilter
            paginationView
            isDev={isDev}
            isSuperUser={user && user.is_superuser}
            setStatusHighlight={setStatusHighlightCallback}
            setTypeFilter={setTypeFilterCallback}
            statusHighlight={statusHighlight}
            typeFilter={typeFilter}
            onClickTypeChipCallback={onClickTypeChipCallback}
          />
          <Box style={{ height: 'calc(100% - 40px)' }}>
            <DataComponent isLoading={loading} loadingComponent={<Loader />}>
              <SharedOrderTable
                FormAtoms={FormAtoms}
                handlePageCallback={handlePageCallback}
                orderData={orders}
                orderRefresh={searchHandler}
                page={sharedTablePageNumber}
                setPage={setSharedTablePageNumber}
                totalPages={totalPages}
              />
            </DataComponent>
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
}

export default OrderViewPage;
