import React from 'react';
import OrderDetailTable from '@/shared/orderDetail/OrderDetailTable';

function FillOrderTable({ fills }) {
  return (
    <OrderDetailTable
      isFillsView
      data={fills}
      dataLoading={false}
      title='Fills'
    />
  );
}

export { FillOrderTable };
