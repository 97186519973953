import { ExchangeIcons } from '@/shared/iconUtil';
import { useTheme } from '@emotion/react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {
  Button,
  Collapse,
  Divider,
  Grid,
  Icon,
  Table,
  TableBody,
  TableHead,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import {
  ValueTypography,
  HeaderTypography,
} from '@/shared/components/MuiComponents';
import { Loader } from '@/shared/Loader';
import {
  calculateDurationDisplay,
  numberWithCommas,
  smartRound,
  titleCase,
} from '../../util';
import ProgressBar from '../fields/ProgressBar/ProgressBar';
import { formatDateTime, SummaryAdditionalDisplay } from '../orderTable/util';
import { FillExchangeChart, FillRoleChart } from './charts';
import {
  StyledHeaderTableCell,
  StyledSummaryTableCell,
} from './orderDetailUtils';

const formatInitialValue = (val) => {
  if (!val) {
    return '';
  }

  if (Number(val)) {
    return `(${smartRound(Number(val))}) `;
  }

  return `(${val}) `;
};

// Duplicated from util.jsx for new theme, refactor once dashboard is converted
const parseStatus = (status) => {
  switch (status) {
    case 'SUBMITTED':
      return <ValueTypography color='primary.main'>Submitted</ValueTypography>;
    case 'CANCELED':
      return <ValueTypography color='error.main'>Canceled</ValueTypography>;
    case 'COMPLETE':
      return <ValueTypography color='success.main'>Finished</ValueTypography>;
    case 'SCHEDULED':
      return (
        <ValueTypography color='secondary.main'>Scheduled</ValueTypography>
      );
    case 'PAUSED':
      return <ValueTypography color='info.main'>Paused</ValueTypography>;
    default:
      return <ValueTypography color='primary.main'>Active</ValueTypography>;
  }
};

function OrderSummaryAdditional({
  isAdditionalOpen,
  notes,
  order_condition_normal,
  order_variable_normal,
  order_condition_vars,
  resume_condition_normal,
  max_otc,
  pos_side,
  alpha_tilt,
  engine_passiveness,
  exposure_tolerance,
  pov_limit,
  pov_target,
  schedule_discretion,
  strategy_params,
}) {
  return (
    <Collapse in={isAdditionalOpen}>
      <Divider sx={{ paddingTop: '16px' }} />
      <Grid container spacing={1} sx={{ paddingTop: '16px' }}>
        {notes && (
          <Grid item xs={6}>
            <Stack direction='column' spacing={2}>
              <HeaderTypography>Notes</HeaderTypography>
              <ValueTypography
                sx={{
                  wordWrap: 'break-word', // Ensures words break to the next line if too long
                  overflowWrap: 'break-word', // Breaks long words
                  whiteSpace: 'normal', // Allows text to wrap
                }}
              >
                {notes}
              </ValueTypography>
            </Stack>
          </Grid>
        )}
        {order_condition_normal && (
          <Grid item xs={6}>
            <HeaderTypography>Order Condition</HeaderTypography>
            <ValueTypography>{order_condition_normal}</ValueTypography>
            {Object.entries(order_variable_normal).map(([k, v]) => {
              return (
                <li key={`clause ${k}`}>
                  {k}: {v} = {order_condition_vars[k][1]}{' '}
                </li>
              );
            })}
          </Grid>
        )}
        {resume_condition_normal && (
          <Grid item xs={6}>
            <HeaderTypography>Resume Condition</HeaderTypography>
            <ValueTypography>{resume_condition_normal}</ValueTypography>
          </Grid>
        )}
        {max_otc && max_otc !== 0 ? (
          <Grid item xs={6}>
            <HeaderTypography>Max OTC Percentage</HeaderTypography>
            <ValueTypography>
              {(Number(max_otc) * 100).toFixed(0)}%
            </ValueTypography>
          </Grid>
        ) : null}
        {pos_side && (
          <Grid item xs={6}>
            <HeaderTypography>Position Side</HeaderTypography>
            <ValueTypography>{pos_side}</ValueTypography>
          </Grid>
        )}
      </Grid>

      <SummaryAdditionalDisplay
        alpha_tilt={alpha_tilt}
        engine_passiveness={engine_passiveness}
        exposure_tolerance={exposure_tolerance}
        pov_limit={pov_limit}
        pov_target={pov_target}
        schedule_discretion={schedule_discretion}
        strategy_params={strategy_params}
      />
    </Collapse>
  );
}

function OrderSummary({ OrderSummaryData, analytics, isSimple = false }) {
  const theme = useTheme();

  const [isAdditionalOpen, setIsAdditionalOpen] = useState(false);

  if (
    OrderSummaryData === undefined ||
    Object.keys(OrderSummaryData).length === 0
  ) {
    return <Loader />;
  }

  const {
    id,
    account_names,
    pair,
    side,
    parent_order = undefined,
    chained_orders = undefined,
    buy_token_amount,
    buy_token,
    sell_token,
    sell_token_amount,
    limit_price,
    is_reverse_limit_price,
    executed_token,
    base_asset,
    executed_notional,
    pct_filled,
    executed_price,
    time_start,
    duration,
    strategy_params,
    trajectory_name,
    super_strategy_name,
    notes,
    order_condition_normal,
    order_variable_normal,
    order_condition_vars,
    executed_buy_qty,
    executed_qty,
    schedule_discretion,
    engine_passiveness,
    alpha_tilt,
    exposure_tolerance,
    pov_limit,
    pov_target,
    status,
    initial_order_params,
    market_type,
    max_otc,
    pos_side,
    resume_condition_normal,
    unique_venues,
    quote_asset,
    target_token,
    target_order_qty,
    is_target_base,
    fill_role_breakdown,
  } = OrderSummaryData;

  const hasSuperStrategy =
    super_strategy_name !== undefined && super_strategy_name !== null;
  const isPov = !!pov_target;

  const displayTargetToken = target_token;
  const displayNotionalToken = quote_asset;

  const calculateDisplayTargetNotional = () => {
    const label = market_type !== 'option' ? displayNotionalToken : 'Contracts';

    // if buying sell token is always quote asset
    if (side === 'buy') {
      return `${numberWithCommas(smartRound(sell_token_amount, 2))} ${label}`;
    }

    // if selling BUT we're targeting quote, just return use it
    if (!is_target_base) {
      return `${numberWithCommas(smartRound(target_order_qty, 2))} ${label}`;
    }

    // otherwise, we have to calculate the notional
    // to avoid fetching it, we just wait for executed price to calculate it
    if (!executed_price) {
      return '-';
    }

    return `${numberWithCommas(smartRound(sell_token_amount * executed_price, 2))} ${label}`;
  };

  const renderDurationOrParticipation = () => {
    if (isPov) {
      return <ValueTypography>{pov_target * 100}%</ValueTypography>;
    }

    return (
      <ValueTypography>
        {initial_order_params.duration &&
          `(${calculateDurationDisplay(initial_order_params.duration)})`}{' '}
        {calculateDurationDisplay(duration)}
      </ValueTypography>
    );
  };

  return (
    <div>
      <HeaderTypography>Accounts</HeaderTypography>
      <Stack
        direction='row'
        spacing={2}
        sx={{ alignItems: 'center', pl: '4px' }}
      >
        <ExchangeIcons
          exchanges={unique_venues}
          style={{ height: '1rem', width: '1rem' }}
        />
        <ValueTypography>
          {account_names &&
            account_names.map((names, index) => {
              if (index === account_names.length - 1) {
                return `${names}`;
              }
              return `${names}, `;
            })}
        </ValueTypography>
      </Stack>

      <Table>
        <TableHead>
          <StyledHeaderTableCell style={{ width: '50%' }}>
            <HeaderTypography>Pair</HeaderTypography>
          </StyledHeaderTableCell>
          <StyledHeaderTableCell style={{ width: '50%' }}>
            <HeaderTypography>Side</HeaderTypography>
          </StyledHeaderTableCell>
        </TableHead>
        <TableBody>
          <StyledSummaryTableCell>
            <ValueTypography>{pair}</ValueTypography>
          </StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <ValueTypography
              color={titleCase(side) === 'Buy' ? 'charts.green' : 'charts.red'}
            >
              {titleCase(side)}
            </ValueTypography>
          </StyledSummaryTableCell>
        </TableBody>
      </Table>

      <Table>
        <TableHead>
          <StyledHeaderTableCell style={{ width: '50%' }}>
            <HeaderTypography>Target Quantity</HeaderTypography>
          </StyledHeaderTableCell>
          <StyledHeaderTableCell style={{ width: '50%' }}>
            <HeaderTypography>Target Notional</HeaderTypography>
          </StyledHeaderTableCell>
        </TableHead>
        <TableBody>
          <StyledSummaryTableCell>
            {buy_token_amount ? (
              <div>
                <ValueTypography>
                  {formatInitialValue(initial_order_params.buy_token_amount)}
                  {numberWithCommas(smartRound(buy_token_amount))}{' '}
                  {market_type !== 'option' ? displayTargetToken : 'Contracts'}
                </ValueTypography>
              </div>
            ) : (
              <div>
                <ValueTypography>
                  {formatInitialValue(initial_order_params.sell_token_amount)}
                  {numberWithCommas(smartRound(sell_token_amount))}{' '}
                  {market_type !== 'option' ? displayTargetToken : 'Contracts'}
                </ValueTypography>
              </div>
            )}
          </StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <ValueTypography>
              {calculateDisplayTargetNotional()}
            </ValueTypography>
          </StyledSummaryTableCell>
        </TableBody>
      </Table>

      <Table>
        <TableHead>
          {hasSuperStrategy ? (
            <StyledHeaderTableCell style={{ width: '50%' }}>
              <HeaderTypography>Strategy</HeaderTypography>
            </StyledHeaderTableCell>
          ) : (
            <StyledHeaderTableCell style={{ width: '50%' }}>
              <HeaderTypography>Trajectory</HeaderTypography>
            </StyledHeaderTableCell>
          )}
          <StyledHeaderTableCell style={{ width: '50%' }}>
            <HeaderTypography>
              {isPov ? 'Target Participation' : 'Duration'}
            </HeaderTypography>
          </StyledHeaderTableCell>
        </TableHead>
        <TableBody>
          {hasSuperStrategy ? (
            <StyledSummaryTableCell>
              <ValueTypography>
                {super_strategy_name} ({trajectory_name})
              </ValueTypography>
            </StyledSummaryTableCell>
          ) : (
            <StyledSummaryTableCell>
              <ValueTypography>{trajectory_name}</ValueTypography>
            </StyledSummaryTableCell>
          )}
          <StyledSummaryTableCell>
            {renderDurationOrParticipation()}
          </StyledSummaryTableCell>
        </TableBody>
      </Table>

      {limit_price && limit_price.length > 0 && (
        <Table>
          <TableHead>
            <StyledHeaderTableCell>
              <Stack direction='row' spacing={1}>
                <HeaderTypography>Limit Price</HeaderTypography>
                {is_reverse_limit_price && (
                  <Tooltip title='Reverse Limit Price'>
                    <SwapVertIcon />
                  </Tooltip>
                )}
              </Stack>
            </StyledHeaderTableCell>
          </TableHead>
          <TableBody>
            <StyledSummaryTableCell>
              <ValueTypography>
                {formatInitialValue(initial_order_params.limit_price)}
                {parseFloat(limit_price) <= 0 ? 'N/A' : limit_price}
              </ValueTypography>
            </StyledSummaryTableCell>
          </TableBody>
        </Table>
      )}

      <OrderSummaryAdditional
        alpha_tilt={alpha_tilt}
        engine_passiveness={engine_passiveness}
        exposure_tolerance={exposure_tolerance}
        isAdditionalOpen={isAdditionalOpen}
        max_otc={max_otc}
        notes={notes}
        order_condition_normal={order_condition_normal}
        order_condition_vars={order_condition_vars}
        order_variable_normal={order_variable_normal}
        pos_side={pos_side}
        pov_limit={pov_limit}
        pov_target={pov_target}
        resume_condition_normal={resume_condition_normal}
        schedule_discretion={schedule_discretion}
        strategy_params={strategy_params}
      />

      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          sx={{
            color: theme.palette.text.dark,
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            justifyContent: 'center',
          }}
          onClick={() => setIsAdditionalOpen(!isAdditionalOpen)}
        >
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'text.secondary',
            }}
            variant='small2'
          >
            {isAdditionalOpen ? 'Hide Details' : 'Show Details'}
          </Typography>
          <Icon
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {isAdditionalOpen ? <ExpandLess /> : <ExpandMore />}
          </Icon>
        </Button>
      </Box>
      <Divider />

      <Table>
        <TableHead>
          <StyledHeaderTableCell style={{ width: '50%' }}>
            <HeaderTypography>Time Start</HeaderTypography>
          </StyledHeaderTableCell>
          <StyledHeaderTableCell style={{ width: '50%' }}>
            <HeaderTypography>Status</HeaderTypography>
          </StyledHeaderTableCell>
        </TableHead>
        <TableBody>
          <StyledSummaryTableCell>
            <ValueTypography>
              {time_start ? formatDateTime(time_start) : '-'}
            </ValueTypography>
          </StyledSummaryTableCell>
          <StyledSummaryTableCell>{parseStatus(status)}</StyledSummaryTableCell>
        </TableBody>
      </Table>

      <Table>
        <TableHead>
          <StyledHeaderTableCell style={{ width: '50%' }}>
            <HeaderTypography>Executed Notional</HeaderTypography>
          </StyledHeaderTableCell>
          <StyledHeaderTableCell style={{ width: '50%' }}>
            <HeaderTypography>Avg Execution Price</HeaderTypography>
          </StyledHeaderTableCell>
        </TableHead>
        <TableBody>
          {market_type !== 'option' && (
            <StyledSummaryTableCell>
              <ValueTypography>
                ${numberWithCommas(smartRound(Number(executed_notional), 2))}
              </ValueTypography>
            </StyledSummaryTableCell>
          )}
          <StyledSummaryTableCell>
            <ValueTypography>
              ${numberWithCommas(smartRound(Number(executed_price), 2))}
            </ValueTypography>
          </StyledSummaryTableCell>
        </TableBody>
      </Table>
      <Table>
        <TableHead>
          <StyledHeaderTableCell>
            <HeaderTypography>Fill Percentage</HeaderTypography>
          </StyledHeaderTableCell>
        </TableHead>
        <TableBody>
          <StyledSummaryTableCell sx={{ padding: '4px 4px 16px 8px' }}>
            <ProgressBar
              fullWidth
              containerStyleOverride={{ width: '100%' }}
              isPov={pov_limit || pov_target}
              orderStatus={status}
              progress={Math.round(Number(pct_filled))}
            />
          </StyledSummaryTableCell>
        </TableBody>
      </Table>

      {!isSimple && (
        <div style={{ width: '100%' }}>
          <FillRoleChart data={fill_role_breakdown} height='32px' />
        </div>
      )}
      <div style={{ width: '100%' }}>
        <FillExchangeChart data={analytics} height='32px' />
      </div>
    </div>
  );
}

export { OrderSummary };
