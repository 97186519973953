import { createTheme } from '@mui/material/styles';
import typography from './typography';
import palette from './colors';
import breakpoints from './breakpoints';

const theme = createTheme({
  typography: {
    allVariants: {
      color: 'rgb(230, 230, 230)',
      fontFamily: ['IBM PLEX MONO'].join(','),
    },
    fontFamily: ['IBM PLEX MONO'].join(','),
    h1: {
      fontSize: typography.fontSizes[1100],
      lineHeight: typography.lineHeights[1100],
      fontWeight: typography.fontWeights.weight600,
    },
    h2: {
      fontSize: typography.fontSizes[1000],
      lineHeight: typography.lineHeights[1000],
      fontWeight: typography.fontWeights.weight600,
    },
    h3: {
      fontSize: typography.fontSizes[900],
      lineHeight: typography.lineHeights[900],
      fontWeight: typography.fontWeights.weight600,
    },
    h4: {
      fontSize: typography.fontSizes[800],
      lineHeight: typography.lineHeights[800],
      fontWeight: typography.fontWeights.weight600,
    },
    h5: {
      fontSize: typography.fontSizes[700],
      lineHeight: typography.lineHeights[700],
      fontWeight: typography.fontWeights.weight600,
    },
    h6: {
      fontSize: typography.fontSizes[600],
      lineHeight: typography.lineHeights[600],
      fontWeight: typography.fontWeights.weight600,
    },
    title1: {
      fontSize: typography.fontSizes[700],
      lineHeight: typography.lineHeights[400],
      fontWeight: typography.fontWeights.weight400,
      fontFamily: ['IBM PLEX MONO'].join(','),
    },
    subtitle1: {
      fontSize: typography.fontSizes[500],
      lineHeight: typography.lineHeights[500],
      fontWeight: typography.fontWeights.weight500,
    },
    button: {
      textTransform: 'none',
      fontSize: typography.fontSizes[400],
      fontWeight: typography.fontWeights.weight400,
      lineHeight: typography.lineHeights[400],
    },
    body1: {
      fontSize: typography.fontSizes[400],
      lineHeight: typography.lineHeights[400],
      fontWeight: typography.fontWeights.weight400,
    },
    body2: {
      fontSize: typography.fontSizes[300],
      lineHeight: typography.lineHeights[300],
      fontWeight: typography.fontWeights.weight300,
    },
    body3: {
      fontSize: typography.fontSizes[200],
      lineHeight: typography.lineHeights[200],
      fontWeight: typography.fontWeights.weight300,
      fontFamily: ['IBM PLEX MONO'].join(','),
    },
    small1: {
      fontSize: typography.fontSizes[200],
      lineHeight: typography.lineHeights[200],
      fontWeight: typography.fontWeights.weight200,
      fontFamily: ['IBM PLEX MONO'].join(','),
    },
    small2: {
      fontSize: typography.fontSizes[100],
      lineHeight: typography.lineHeights[100],
      fontWeight: typography.fontWeights.weight100,
      fontFamily: ['IBM PLEX MONO'].join(','),
    },
    cardTitle: {
      fontSize: typography.fontSizes[500],
      lineHeight: typography.lineHeights[500],
      fontWeight: typography.lineHeights[400],
      fontFamily: ['IBM PLEX MONO'].join(','),
    },
  },
  palette: {
    mode: 'dark',
    text: {
      primary: palette.base.white,
      subtitle: palette.grey[400],
      dark: palette.grey[500],
      disabled: palette.grey[300],
      offBlack: palette.black[700],
    },
    primary: {
      light: palette.brand[400],
      main: palette.brand[500],
      dark: '#ffad591A',
      transparent: palette.brandAlpha[500],
    },
    secondary: {
      main: palette.black[200],
    },
    info: {
      main: 'rgba(59, 175, 218)',
    },
    success: {
      main: '#0ecb81', // temporarily match green of rest of legacy app
      dark2: '#02472c',
    },
    error: {
      main: palette.error[500],
      dark2: '#5c141e',
    },
    background: {
      base: palette.black[600],
      container: palette.black[800],
      card: palette.base.grey,
      paper: '#1E1E1E',
    },
    charts: {
      red: palette.error[500],
      redTransparent: 'rgba(246, 70, 93, 0.5)',
      green: '#0ecb81',
      greenTransparent: 'rgba(14, 203, 129, 0.5)',
      gray: palette.grey[400],
      grayTransparent: 'rgba(150, 150, 150, 0.1)',
      pinkTransparent: 'rgba(20,20,20, 0.2)',
      orange: '#ffa344',
      orangeTransparent: 'rgba(255, 163, 68, 0.5)',
      blue: 'rgba(57, 124, 191, 0.8)',
      gridLines: palette.black[600],
      OTC: 'rgb(255, 115, 0)',
      OTCTransparent: 'rgba(255, 115, 0, 0.5)',
      offWhite: palette.grey[25],
    },
    portfolioChart: {
      line: palette.brand[500],
      areaGradientTop: 'rgba(244, 163, 69, 0.3)',
      areaGradientBottom: 'rgba(20,20,20, 0.2)',
      ticks: palette.grey[400],
    },
    card: {
      light: palette.grey[950],
    },
    exchangeBalance: {
      1: '#2E0038',
      2: '#5D2C6F', // Interpolated between #2E0038 and #C5EBC3
      3: '#8A5989', // Interpolated between #C5EBC3 and #B7C8B5
      4: '#9F6C7F', // Interpolated between #B7C8B5 and #A790A5
      5: '#B25D76', // Interpolated between #A790A5 and #CC7B2F
      6: '#CF8A47', // Interpolated between #CC7B2F and #C5EBC3
      7: '#D8B5A2', // Interpolated between #C5EBC3 and #B7C8B5
      8: '#E4D3A4', // Interpolated between #B7C8B5 and #CC7B2F
    },
    side: {
      buy: '#0ecb81',
      sell: palette.error[500],
    },
    orderUrgency: {
      background: '#FFFFFF1A',
      unselected: '#85888E',
      ULTRA_LOW: '#10E090',
      LOW: '#0ECB81',
      MEDIUM: '#CECFD2',
      HIGH: '#FD7279',
      ULTRA_HIGH: '#F74551',
    },
    exchangeColors: {
      OKX: 'rgb(169, 169, 169)',
      OKXTransparent: 'rgba(169, 169, 169, 0.75)',
      Binance: 'rgb(230, 181, 26)',
      BinanceTransparent: 'rgba(230, 181, 26, 0.75)',
      Bybit: '#ffa344',
      BybitTransparent: '#ffa34444',
      Deribit: 'rgb(51, 204, 204)',
      DeribitTransparent: 'rgba(51, 204, 204, 0.75)',
      Coinbase: 'rgb(26, 127, 229)',
      CoinbaseTransparent: 'rgba(26, 127, 229, 0.75)',
      MockExchange: 'rgb(255, 255, 255)',
      MockExchangeTransparent: 'rgba(255, 255, 255, 0.75)',
    },
  },
  breakpoints: {
    values: breakpoints,
  },
  spacing: 4,
  button: {
    textTransform: 'none',
  },
  fontFamily: ['IBM PLEX MONO'].join(','),
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundImage: 'None',
          backgroundColor: palette.base.grey,
          padding: 0,
          height: '100%',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation0: {
          backgroundColor: palette.base.grey,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          height: 'calc(100% - 32px)',
          padding: '16px',
          '&:last-child': {
            paddingBottom: '16px',
          },
        },
      },
    },
    StyledTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: palette.base.black,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: 'rgb(230, 230, 230)',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'rgb(230, 230, 230)',
          fontSize: '0.75rem',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
  },
});

export { theme };
