/**
 * This module provides functionality for fetching and formatting blockchain events related to attestations.
 * It includes utilities for fetching both trade data and risk attestation events from the blockchain,
 * with support for pagination, retries, error handling, and concurrent batch processing.
 *
 * Key Features:
 * - Concurrent batch processing with configurable pool size
 * - Automatic retry logic for failed requests
 * - Pagination support for large event ranges
 * - Fallback to raw log parsing if event filtering fails
 * - Detailed debug logging
 *
 * The module exports:
 * - fetchEvents: Core function for fetching events with retry logic and concurrent batch processing
 * - formatTradeEvent: Formats raw trade attestation events into a standardized structure
 * - formatRiskEvent: Formats raw risk attestation events into a standardized structure 
 * - createEmptyEvent: Creates an empty event object with default values
 * - fetchAttestedToDataEvents: Fetches trade attestation events
 * - fetchAttestedToRiskEvents: Fetches risk attestation events
 *
 * The module uses ethers.js for blockchain interaction and implements a custom
 * PromiseSemaphore class for managing concurrent requests.
 */

// TODO: Migrate to: import { getContract, getAllEvents } from '@treadfi/contracts';
import { ethers } from 'ethers';
import { promisePool } from './PromiseSemaphore';
import { abis } from './ProofAbis';
import { findLatestActiveBlock } from './findLatestActiveBlock';

const BLOCK_STEP_SIZE = 10_000;
const MAX_EMPTY_BATCHES = 200;
const REFRESH_INTERVAL = 600_000; // 10 minutes

/**
 * Fetches a single batch of events from the blockchain
 * @param {Object} config - Configuration object containing:
 * @param {string} config.rpcUrl - URL of the RPC endpoint
 * @param {string} config.attestationAddress - Address of attestation contract
 * @param {number} config.fromBlock - Starting block number to fetch from
 * @param {number} config.toBlock - Ending block number to fetch to
 * @param {string} eventName - Name of event to fetch
 * @param {Function} formatEvent - Function to format the events
 * @returns {Promise<{events: Array, lastCheckedBlock: number}>}
 */
export async function fetchEventsBatch(config, eventName, formatEvent) {
  const { rpcUrl, attestationAddress, fromBlock, toBlock } = config;

  // Ensure block numbers are valid numbers and not NaN
  const safeFromBlock = Math.max(0, Number(fromBlock));
  const safeToBlock = Math.max(safeFromBlock, Number(toBlock));

  console.debug(`[fetchEventsBatch:${eventName}] Fetching batch:`, {
    fromBlock: safeFromBlock,
    toBlock: safeToBlock,
    attestationAddress,
    originalConfig: { fromBlock, toBlock } // Log original values for debugging
  });

  const provider = new ethers.JsonRpcProvider(rpcUrl);
  const contract = new ethers.Contract(attestationAddress, abis, provider);

  // Debug contract interface and event details
  const eventFragment = contract.interface.getEvent(eventName);
  const eventSignature = eventFragment.format();
  const eventTopic = ethers.id(eventSignature);

  console.debug(`[fetchEventsBatch:${eventName}] Contract and event details:`, {
    // Ensure contract is correctly logged
    contract: {
      address: await contract.getAddress(),
      interface: {
        fragments: contract.interface.fragments.map((f) => f.name),
        hasEvent: contract.interface.hasEvent(eventName),
        eventFragment: eventFragment
          ? {
            name: eventFragment.name,
            inputs: eventFragment.inputs.map((i) => ({
              name: i.name,
              type: i.type,
              indexed: i.indexed,
            })),
          }
          : null,
      },
    },
    // Ensure provider is correctly logged
    provider: {
      network: await provider.getNetwork().then((n) => ({
        name: n.name,
        chainId: n.chainId,
      })),
      ready: provider.ready,
    },
  });

  try {
    // Convert block numbers to hex strings as required by some providers
    const blockParams = {
      fromBlock: ethers.toBeHex(safeFromBlock),
      toBlock: ethers.toBeHex(safeToBlock)
    };
    const [filterEvents, logEvents] = await Promise.all([
      contract.queryFilter(eventName, blockParams.fromBlock, blockParams.toBlock),
      provider.getLogs({
        address: attestationAddress,
        topics: [eventTopic],
        ...blockParams
      }),
    ]);
    console.debug(`[fetchEventsBatch:${eventName}]: For blocks ${safeFromBlock} to ${safeToBlock}, Found ${filterEvents.length} filter events; Found ${logEvents.length} log events`);
    const events = filterEvents.length
      ? filterEvents
      : logEvents
        .map((log) => {
          try {
            return contract.interface.parseLog(log);
          } catch (e) {
            console.error(`[fetchEventsBatch:${eventName}] Failed to parse log:`, e, log);
            return null;
          }
        })
        .filter(Boolean);
    return {
      events: events.map(formatEvent),
      lastCheckedBlock: safeFromBlock,
    };
  } catch (error) {
    console.error(`[fetchEventsBatch:${eventName}] Error fetching events:`, {
      error,
      config: {
        fromBlock: safeFromBlock,
        toBlock: safeToBlock,
        attestationAddress
      }
    });
    throw error;
  }
}

// Event formatters
/**
 * Formats a trade attestation event into a standardized object structure
 * @param {Object} event - The raw blockchain event
 * @param {string} event.transactionHash - Hash of the transaction
 * @param {number} event.blockNumber - Block number where event occurred
 * @param {Object} event.args - Event arguments
 * @param {string} event.args.traderId - ID of the trader
 * @param {number} event.args.epoch - Epoch number
 * @param {string} event.args.attester - Address of the attester
 * @param {Object} event.args.record - Trade record data
 * @param {string} event.args.record.merkleRoot - Merkle root of the trade data
 * @param {string} event.args.record.cid - Content ID for trade data
 * @returns {Object} Formatted trade event object
 */
export const formatTradeEvent = (event) => ({
  transactionHash: event.transactionHash,
  blockNumber: event.blockNumber,
  traderId: event.args.traderId,
  epoch: event.args.epoch,
  attester: event.args.attester,
  data: {
    merkleRoot: event.args.record.merkleRoot,
    cid: event.args.record.cid,
  },
  eventName: 'Data',
  eventColor: 'success',
});

/**
 * Formats a risk attestation event into a standardized object structure
 * @param {Object} event - The raw blockchain event
 * @param {string} event.transactionHash - Hash of the transaction
 * @param {number} event.blockNumber - Block number where event occurred
 * @param {Object} event.args - Event arguments
 * @param {string} event.args.traderId - ID of the trader
 * @param {number} event.args.epoch - Epoch number
 * @param {string} event.args.attester - Address of the attester
 * @param {Array} event.args.record - Risk record data
 * @param {string} event.args.parameterId - ID of the risk parameter
 * @returns {Object} Formatted risk event object
 */
export const formatRiskEvent = (event) => ({
  transactionHash: event.transactionHash,
  blockNumber: event.blockNumber,
  traderId: event.args.traderId,
  epoch: event.args.epoch,
  attester: event.args.attester,
  data: parseInt(event.args.record[0], 10),
  parameterId: event.args.parameterId,
  eventName: 'Risk',
  eventColor: 'warning',
});

/**
 * Creates an empty event object with default values
 * @returns {Object} Empty event object with null/empty values for all fields
 */
export const createEmptyEvent = () => ({
  transactionHash: '',
  blockNumber: null,
  traderId: '',
  epoch: null,
  attester: '',
  data: {},
  eventName: 'Error',
  eventColor: 'error',
})

/**
 * Fetches attestation events for trade data
 * @param {Object} config - Configuration object for blockchain connection
 * @param {string} config.rpcUrl - RPC endpoint URL for the blockchain network
 * @param {string} config.attestationAddress - Contract address of the attestation contract
 * @param {number} config.numberOfBlocks - Number of blocks to query in each batch
 * @param {number} config.retry - Number of retry attempts for failed requests
 * @param {number} config.paginationNumber - Pagination offset for block ranges
 * @returns {Promise<{events: Array, lastCheckedBlock: number}>} Object containing events array and last checked block number
 */
export const fetchAttestedToDataEvents = (config) =>
  fetchEventsBatch(config, 'AttestedToData', formatTradeEvent);

/**
 * Fetches attestation events for risk parameters
 * @param {Object} config - Configuration object for blockchain connection
 * @param {string} config.rpcUrl - RPC endpoint URL for the blockchain network
 * @param {string} config.attestationAddress - Contract address of the attestation contract
 * @param {number} config.numberOfBlocks - Number of blocks to query in each batch
 * @param {number} config.retry - Number of retry attempts for failed requests
 * @param {number} config.paginationNumber - Pagination offset for block ranges
 * @returns {Promise<{events: Array, lastCheckedBlock: number}>} Object containing events array and last checked block number
 */
export const fetchAttestedToRiskEvents = (config) =>
  fetchEventsBatch(config, 'AttestedToRisk', formatRiskEvent);

/**
 * Fetches the latest block number from the blockchain
 * @param {string} rpcUrl - RPC endpoint URL
 * @returns {Promise<number>} Latest block number
 */
export async function fetchLatestBlockNumber(rpcUrl) {
  try {
    const provider = new ethers.JsonRpcProvider(rpcUrl);
    const blockNumber = await provider.getBlockNumber();

    console.debug('[fetchLatestBlockNumber] Got latest block:', blockNumber);
    return blockNumber;
  } catch (error) {
    console.error('[fetchLatestBlockNumber] Error:', error);
    throw error;
  }
}


/**
 * Correlates trade data events with risk events by matching trader ID and epoch
 * @param {Array} dataEvents - Array of trade data attestation events
 * @param {Array} riskEvents - Array of risk attestation events
 * @returns {Object} Object containing original data events and correlated risk events
 */
export function correlateEvents(dataEventsInput = [], riskEventsInput = []) {
  // Ensure we have arrays, even if empty
  const dataEvents = Array.isArray(dataEventsInput) ? dataEventsInput : [];
  const riskEvents = Array.isArray(riskEventsInput) ? riskEventsInput : [];

  // Create lookup maps for both types of events
  const dataMap = new Map();
  const riskMap = new Map();

  // Index data events by traderId-epoch, keeping only the first occurrence
  dataEvents.forEach((dataEvent) => {
    if (dataEvent && dataEvent.traderId && dataEvent.epoch) {
      const key = `${dataEvent.traderId}-${dataEvent.epoch}`;
      if (!dataMap.has(key)) {
        dataMap.set(key, dataEvent);
      }
    }
  });

  // Index risk events by traderId-epoch
  riskEvents.forEach((riskEvent) => {
    if (riskEvent && riskEvent.traderId && riskEvent.epoch) {
      const key = `${riskEvent.traderId}-${riskEvent.epoch}`;
      if (!riskMap.has(key)) {
        riskMap.set(key, []);
      }
      riskMap.get(key).push(riskEvent);
    }
  });

  // Return unique Data events with grouped Risks
  return Array.from(dataMap.values())
    .filter(Boolean)
    .map((dataEvent) => {
      if (!dataEvent?.traderId || !dataEvent?.epoch) return null;
      const key = `${dataEvent.traderId}-${dataEvent.epoch}`;
      return {
        traderId: dataEvent.traderId,
        epoch: dataEvent.epoch,
        blockNumber: dataEvent.blockNumber,
        dataEvent,
        riskEvents: riskMap.get(key) || [],
      };
    })
    .filter(Boolean);
}

/**
 * Fetches events until enough unique trader-epoch pairs are found
 * @param {Object} config - Configuration object containing:
 * @param {string} config.rpcUrl - URL of the RPC endpoint
 * @param {string} config.attestationAddress - Address of attestation contract
 * @param {number} config.numberOfBlocks - Number of blocks to scan in total
 * @param {number} config.retry - Number of retry attempts for failed requests
 * @param {number} config.paginationNumber - Number of events to fetch per page
 * @param {number} rowsPerPage - Number of events to fetch per page
 * @param {number} startFromBlock - Optional override for starting block
 * @returns {Promise<{events: Array, lastCheckedBlock: number}>} Object containing events array and last checked block number
 */
export async function fetchUntilEnoughEvents(
  config,
  rowsPerPage,
  startFromBlock = null
) {
  let allEvents = [];
  let emptyBatchCount = 0;
  let blockPointer = startFromBlock ??
    (await findLatestActiveBlock(config.rpcUrl, config.attestationAddress));

  /* eslint-disable no-await-in-loop */
  while (
    // Should fetch until we have enough events to fill the page
    allEvents.length < rowsPerPage &&
    // Should fetch until we have checked too many empty batches
    emptyBatchCount < MAX_EMPTY_BATCHES &&
    // Should fetch until we have checked all blocks
    blockPointer > 0
  ) {
    const batchConfig = {
      ...config,
      fromBlock: Math.max(0, blockPointer - BLOCK_STEP_SIZE),
      toBlock: blockPointer,
    };

    const batchTasks = [
      () => fetchAttestedToDataEvents(batchConfig),
      () => fetchAttestedToRiskEvents(batchConfig)
    ];

    const [dataResult, riskResult] = await promisePool(batchTasks, 2);
    const correlatedEvents = correlateEvents(
      dataResult.events,
      riskResult.events
    );

    if (correlatedEvents.length > 0) {
      allEvents = [...allEvents, ...correlatedEvents];
      emptyBatchCount = 0;
    } else {
      emptyBatchCount += 1;
    }

    blockPointer = Math.min(
      dataResult.lastCheckedBlock,
      riskResult.lastCheckedBlock
    );

    console.log('[fetchUntilEnoughEvents] at emptyBatchCount', emptyBatchCount, 'allEvents', allEvents.length, {
      rowsPerPage,
      maxEmptyBatches: MAX_EMPTY_BATCHES,
      blockPointer
    });
  }
  /* eslint-enable no-await-in-loop */

  return {
    events: allEvents,
    lastCheckedBlock: blockPointer
  };
}
